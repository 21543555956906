import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionsDirective } from './user-permissions.directive';
import { AdminProfileCheck } from './user-profile.directive';
import { UserPermissionsAttrDirective } from './user-permission-attr.directive';
import { AccountBreakpointVersion } from './check-account-access.directive';
import { AccountBreakpointVersionMaterialComponent } from './check--access-mat-component.directive';
import { StylePaginatorDirective } from './style-paginator.directive';



@NgModule({
  declarations: [
    UserPermissionsDirective, 
    AdminProfileCheck,
    UserPermissionsAttrDirective,
    AccountBreakpointVersion,
    AccountBreakpointVersionMaterialComponent,
    StylePaginatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UserPermissionsDirective,
    AdminProfileCheck,
    UserPermissionsAttrDirective,
    AccountBreakpointVersion,
    AccountBreakpointVersionMaterialComponent,
    StylePaginatorDirective
  ],
})
export class DirectivesModule { }
