import {Component, HostBinding, ViewEncapsulation} from '@angular/core'
import {HeaderService} from './shared/header/header.service'
import {AuthService} from './features/auth/auth.service'
import {Router} from "@angular/router";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {BaseComponentComponent} from "./shared/base-component/base-component.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseComponentComponent{
  @HostBinding('class') className = ''

  currentRoute = ''
  constructor(private headerService: HeaderService,
              public readonly authService: AuthService,
              private router: Router,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {

    super()

    this.matIconRegistry.addSvgIcon(
        "tracker",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/tracker.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "tool-bar-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/tool-bar-header-menu.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "green-check-circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/green-check-circle.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "close-tab-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/close-tab-icon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "fatura-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/fatura-icon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "open-eye-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/open-eye-icon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "client-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/client-icon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "vector-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/vector-icon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "facebook-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/facebook.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "instagram-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/instagram.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "whatsapp-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/whatsapp.svg")
    );
    this.matIconRegistry.addSvgIcon(
        "whatsapp-black-icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/whatsapp-black.svg")
    );



  }

  ngOnInit() {



    this.headerService.darkTheme.subscribe((darkThemeFlag) => {
      const darkClassName = 'dark-mode'
      this.className = darkThemeFlag ? darkClassName : ''
    })
  }
}
