import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-utilidades',
  templateUrl: './utilidades.component.html',
  styleUrls: ['./utilidades.component.scss']
})
export class UtilidadesComponent implements OnInit {

  @Input() user: any
  clientAreaLink: string =''
  loggedAccountId: number

  constructor(
    private _snackBar: MatSnackBar,
    private _userService: UserService,
    private _authService: AuthService
  ) { }

  handleCopyText() {
    
    navigator.clipboard.writeText(this.clientAreaLink);
    
    this._snackBar.open('Copiado', 'OK', {
      duration: 2000,
      panelClass: 'agil-snackbar'
    });

  }

  getCompanyName() {
    this._userService.getCentralName(this.loggedAccountId).subscribe((res)=> {
      this.clientAreaLink = `${location.origin}/area-cliente/${this.loggedAccountId}/${res}`
    })
  }

  ngOnInit(): void {
    const token = this._authService.token
    const decodedToken = this._authService.decodeJwtToken(token)
    this.loggedAccountId = decodedToken.sub.cliente_conor_id
    this.getCompanyName()
  }
}
