import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'

interface ViaCepReturn {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
}

@Injectable({
  providedIn: 'root'
})
export class CepService {
  constructor(private httpClient: HttpClient) {}

  searchByCep(formGroup: FormGroup): void {
    const cep = formGroup.controls['cep'].value
    // TO-DO Send request without auth on header
    if (cep.length === 8) {
      this.httpClient
        .get<ViaCepReturn>(`https://viacep.com.br/ws/${cep}/json/`)
        .subscribe((data: ViaCepReturn) => {
          formGroup.controls['address'].setValue(data.logradouro)
          formGroup.controls['neighbourAddress'].setValue(data.bairro)
          formGroup.controls['complementAddress'].setValue(data.complemento)
          formGroup.controls['city'].setValue(data.localidade)
          formGroup.controls['state'].setValue(data.uf)
        })
    }
  }
}
