import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventUpdateDataService {
  private onDataUpdatedSource = new Subject<void>();
  onDataUpdated$ = this.onDataUpdatedSource.asObservable();

  emitDataUpdated(): void {
    this.onDataUpdatedSource.next();
  }
}
