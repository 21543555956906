import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Output() showEditComponentEmitter = new EventEmitter()
  @Input() user: any

  constructor() { }

  handleEditClick(): void {
    this.showEditComponentEmitter.emit()
  }

  ngOnInit(): void {
  }

}
