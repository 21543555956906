import { Quote } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {


  constructor(
    private _userService: UserService,
    private _authService: AuthService
  ) {}

  user = {}
  isAdmin :boolean = false
  editMode:boolean = false

  ngOnInit(): void {
    this.getLoggedUser()
    this.isAdmin = this._authService.isMasterProfile()
  }

  hideEditComponent(reload?:any): void {
    this.editMode = !this.editMode

    if(reload)
    this.getLoggedUser()
  }

  showEditComponent(reload?:any): void {
    this.editMode = true

    if(reload)
    this.getLoggedUser()
  }

  getLoggedUser(): void {

    this._userService.getLoggedUser().subscribe( (data) => {
      this.user = data
    })

  }

}
