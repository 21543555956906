<ng-template #modal let-modal>
  <div class="modal-header modal-header-detail">
    <h3 class="modal-title">{{ modalConfig.modalTitle }}</h3>

    <div class="div-btn-close">
      <fa-icon
        (click)="modal.dismiss('Cross click')"
        [icon]="faTimes"
        class="btn-close"
      ></fa-icon>
    </div>
  </div>

  <div class="modal-body">
    <div class="body-message justify-content-center">
      <div class="justify-content-center d-flex flex-column">
        <fa-icon
          *ngIf="modalConfig.modalIcon"
          [icon]="modalConfig.modalIcon"
        ></fa-icon>

        <div class="text-modal">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-template>
