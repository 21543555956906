import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/features/auth/auth.service'

@Injectable()
export class TokenAuthorized implements CanActivate {
  constructor(
    private _authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let  token: string = route.queryParams['token']
    if(!token) token = route.params['token']

    const decoded: any = this._authService.decodeJwtToken(token)
    const expStatus: boolean = this._authService.isJwtExpValid(decoded.exp)

    return !!token && expStatus
  }

}