
    <header>
        <h1>Área do cliente</h1>
    </header>
    <section [ngClass]="{'section-phone': isPhone }">
        <div class="ilustration-box">
            <h2>
                Acompanhe suas cobranças <h2 class="base-name"> {{visualIdentity?.name}}</h2>
            </h2>
            <img class="ilustracao" src="../../../../assets/images/client-area-pic.svg" alt="home-ilustrator">
        </div>
        <div class="vertical-line"></div>
        <div class="form-box">
            <div class="form">
                <div>
                    <img class="base-client-logo" [src]="picture" alt="logo-base-client">
                </div>
                <span> 
                    Para acessar suas informações, se identifique informando seu CPF ou CNPJ.
                    <mat-icon matTooltip="Um CPF válido é formado por 11 números e um CNPJ por 14 números."> help </mat-icon>
                </span>
                <form>
                    <mat-form-field [style.width.%]="100" appearance="outline">
                        <input 
                        matInput 
                        placeholder="Ex.: 000.000.000-00"
                        [formControl]="inputCPF"
                        mask="000.000.000-00||00.000.000/0000-00"
                        >
                    </mat-form-field>
                    <p class="error-text" *ngIf="loading && hasError">Digite um CPF ou CNPJ válido
                    </p> 
                    <p class="error-text" *ngIf="invalidInput"> Não encontrado! Verifique o CPF e tente novamente.
                    </p> 

                    <button type="button" mat-raised-button color="primary" (click)="handleFindClient()">Acessar</button>
                </form>
            </div>
        </div>

    </section>

