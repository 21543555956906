import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/features/auth/auth.service'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('viacep')) {
      return next.handle(request)
    } else {
      const duprequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + this.authService.token
        )
      })

      return next.handle(duprequest)
    }
  }
}
