<toolbar (onToggleMenuButton)="adminSideBar.toggleAdminSideBar()" [hidden]="!isPhone"></toolbar>

<section [ngClass]="{'desktop-body': !isPhone}" >


        <app-sidebar-admin *ngIf="this.authService.isAdmin()" #adminSidebar>
            <ng-content>
            </ng-content>
        </app-sidebar-admin>


</section>

