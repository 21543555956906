import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError } from 'rxjs';
import { ErrorBottomSheet } from 'src/app/features/helpers';
import { SucessBottomSheetComponent } from 'src/app/features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { UserService } from '../../user.service';
import { ProfileModel } from '../../user';
import { AuthService } from 'src/app/features/auth/auth.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends BaseComponentComponent implements OnInit , OnChanges {
  UserForm: FormGroup;
  isAdmin:boolean = false
  profilesData: ProfileModel[] 
  activeUserProfile: ProfileModel = {
    id: 0,
    nome: '',
    permissoes: []
  }

  @Output() hideEditComponentEmitter = new EventEmitter()
  @Input() user: any = null
  @Input() selfEdit: boolean = false

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _userService: UserService,
    private _authService: AuthService
  ) { 

    super()

    this.UserForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('',Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.required),
      profile: new FormControl('', Validators.required)

    })
  }
    
  ngOnInit(): void {
    this.isAdmin = this._authService.isMasterProfile()
    this.getProfiles() 
  }

  filterUserProfile(userProfileId:number): void {
    let index = this.profilesData.findIndex(
      profile=> profile.id === userProfileId);
    if(index > -1) this.activeUserProfile = this.profilesData[index]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.user) {
      this.UserForm.controls['name'].setValue(this.user.nome)
      this.UserForm.controls['email'].setValue(this.user.email)
      this.UserForm.controls['profile'].setValue(this.user.perfil_id)
      this.UserForm.controls['password'].setValue('*****')
    }
  }

  resetForm(): void {
    this.UserForm.controls['name'].setValue('')
    this.UserForm.controls['email'].setValue('')
    this.UserForm.controls['profile'].setValue('')
    this.UserForm.controls['password'].setValue('')
  }

  getProfiles() {
    this._userService.profileListEventEmmiter.subscribe((data) => {
      this.profilesData =  data
      this.filterUserProfile(this.user.perfil_id)
    })
    this._userService.getAccessProfiles()
  }

  filter() {

  }

  cancel():void {
    this.resetForm()
    this.hideEditComponentEmitter.emit('')
  }

  handleSaveUpdates(): void {

    let user:any
    if (this.UserForm.valid) {

      if(this.UserForm.controls['password'].value !== '*****'){
        user  = {
          nome: this.UserForm.controls['name'].value,
          email: this.UserForm.controls['email'].value,
          password: this.UserForm.controls['password'].value,
          perfil_id: this.UserForm.controls['profile'].value

        }

      }else{
        user = {
          nome: this.UserForm.controls['name'].value,
          email: this.UserForm.controls['email'].value,
          perfil_id: this.UserForm.controls['profile'].value
        }
      }

      this._userService
      .editUser(user, this.user.id)
      .pipe(
        catchError((err: any) => {
          throw err
        }),
      )
      .subscribe(
        {
          next: () =>  {
            this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: 'Alterações Salvas!',
              }
            )
            this.hideEditComponentEmitter.emit({reloadList: true})
          },
          error: (errorData:any) =>  {
            this._bottomSheet.open(ErrorBottomSheet, 
              { data: 
                {
                  redirectTo: 'profile',
                  error: errorData
                } 
              })
          } 
        }
      )
    }else {
      this.UserForm.markAllAsTouched()
    }



  } 
        
}

  
  
  