<div style="margin-top: 1rem;">

    <div class="nodata" *ngIf="!userData[0] && !showCreateComponent">
        <p class="simple-text"> Nenhum usuário</p>
    </div>

    <div *ngIf="showEditComponent === false ;else editTrue;"></div>
    <div *ngIf="showCreateComponent === false ;else createTrue;"></div>


    <ng-template #createTrue>
        <app-create-new-user
        [user]="user"  
        (hideComponent)="hideCreateComponent($event)"
        ></app-create-new-user>
    </ng-template>



    
    
    <div *ngIf="!showCreateComponent && !showEditComponent">
        <div  
        [ngClass]="{'mobile-card': isPhone, 'card': !isPhone}"
        *ngFor="let user of userData" >
            <div class="name">
                <p class="simple-text">Nome:</p>
                <span class="bold-text">{{user?.nome}}</span> 
            </div>  

            <div class="email">
                <p class="simple-text">Email:</p>
                <span class="bold-text">{{user?.email}}</span> 
            </div>  

            <div class="configs-btn">
                <button 
                mat-icon-button  
                matTooltip="Editar usuário"  
                matTooltipPosition="above" 
                (click)="editButtonHandler(user)"
                >
                    <mat-icon>edit</mat-icon>
                </button>

                <button 
                mat-icon-button  
                matTooltipPosition="above" 
                matTooltip="Deletar"
                (click)="handleDeleteUser(user.id)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <mat-divider>
            </mat-divider>
        </div>
    </div>

</div>

<button 
    matTooltip="Criar usuário"
    *ngIf="!showCreateComponent"
    mat-fab 
    color="primary" 
    (click)="createButtonHandler()"
    class="agil-web-fab"
    >
    <mat-icon>add</mat-icon>
</button>

<ng-template #editTrue>
    <app-edit-user 
    [user]="userToEdit"
    (hideEditComponentEmitter)="hideEditComponent($event)"
    ></app-edit-user>
</ng-template>


