import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLinkRoutingModule } from './external-links-routing.module';
import { RouterModule } from '@angular/router';
import { OsComponent } from './os/os.component';
import { ServiceOrderModule } from '../service-order/service-order.module';



@NgModule({
  declarations: [OsComponent],
  imports: [
    CommonModule,
    ExternalLinkRoutingModule,
    ServiceOrderModule
  ],
  exports: [RouterModule, ExternalLinkRoutingModule]
})
export class ExternalLinksModule { }
