<mat-drawer-container class="conteiner-desktop">

    <mat-drawer disableClose #drawer [ngClass]="{'sidenav-desktop': !isPhone}"  class="" [mode]="isPhone? 'push': 'side'" [opened]="!isPhone">
        
        <div  [ngClass]="{'navigation-list-desktop': !isPhone}">

            <mat-nav-list role="navigation">   
                
                <mat-list-item *ngIf="!isPhone" routerLink="/profile" [routerLinkActive]="['is-active']">
                    <img 
                    (click)="goToHomePage()"
                    src="../../../../assets/images/logo/logo-agilweb-black-orange.svg" 
                    alt="Agilweb logo image" 
                    class="logo">
                </mat-list-item>

                
                <mat-list-item  routerLink="/admin" [routerLinkActive]="['is-active']">
                    <mat-icon mat-list-icon class="material-icons-round"> person </mat-icon>
                    <span mat-line> Contas </span>
                </mat-list-item>

                <mat-list-item (click)="handleLogout()" class="last-item" >
                    <mat-icon mat-list-icon class="material-icons-round">logout</mat-icon>
                    <span mat-line>Sair</span>
                </mat-list-item>
                 
            </mat-nav-list>

        </div>

    </mat-drawer>

    <mat-drawer-content [ngClass]="{'desktop-content': !isPhone,   'phone-content' : isPhone}" >
        <ng-content></ng-content>

    </mat-drawer-content>
</mat-drawer-container>