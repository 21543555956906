import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileModel, UserModelRequest } from './user';
import { AccountModel } from '../admin/account';
import { UserModel } from './profile/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private profileList$: Observable<ProfileModel> | null = null
  public profileListEventEmmiter: EventEmitter<any> =
  new EventEmitter()

  constructor(
    private httpClient: HttpClient,
    ) { }

  public getLoggedUser(): Observable<any> {
    return this.httpClient
        .get<any>(environment.apiUrl + '/user');
  }

  public getUser(id: any): Observable<any> {
    return this.httpClient
        .get<any>(environment.apiUrl + '/user/'+id);
  }

  public editUser(userModel:any, id:any) {
    return this.httpClient
    .put<any>(environment.apiUrl + '/user/'+id, userModel);
  }

  public getCentralName(id:number):Observable<any>{
    return this.httpClient.get<any>(environment.apiUrl + '/user/central/'+id)
  }

  public getAccessProfiles() {
    this.profileList$ = this.httpClient.get<any>(environment.apiUrl + '/profile/list')

    this.profileList$.subscribe((data) => {
      this.profileListEventEmmiter.emit(data)
    })
  }

  public saveNewUser(account: UserModelRequest) {
    return this.httpClient.post<UserModelRequest>(
      environment.apiUrl + '/user',
      account
    )
  }

}
