import { HttpClient, HttpParams } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Observable, shareReplay, map } from 'rxjs'
import { environment } from '../../../environments/environment'

import {cnpj, cpf} from 'cpf-cnpj-validator'
import { TelefonePipe } from 'src/app/shared/pipes/telefone.pipe'
import {VehicleModel, VehicleRequest, VehiclesParams} from "./vehicle";
import {CustomerModel} from "../customer/customer";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(
    private _httpClient: HttpClient,
    private _phonePipe: TelefonePipe
  ) {}
  private url = environment.apiUrl + '/veiculos'

  private VehicleList$: Observable<VehicleRequest> | null = null

  public VehicleToEdit: VehicleRequest = {} as VehicleRequest
  public VehicleToDelete: string = ''

  public totalVehicles: number = 0

  public VehicleListEventEmitter: EventEmitter<VehicleRequest> =
    new EventEmitter()

  public clearVehicleCache() {
    this.VehicleList$ = null
    this.getAllVehicles()
  }

  public getAllVehicles(objParams?:VehiclesParams) {

    if(objParams) {

      const params = new HttpParams()
      .set('page', objParams.page)
      .set('page_size', objParams.page_size)
      .set('term', objParams.term)
      .set('tags', objParams.filter?.join(","));

      this.VehicleList$ = this._httpClient
      .get<VehicleRequest>(this.url, {params})
      .pipe(
        map((data) => this.formatVehicles(data)),
        shareReplay(1)
      )

    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      this.VehicleList$ = this._httpClient
      .get<VehicleRequest>(this.url, {params})
      .pipe(
        map((data) => this.formatVehicles(data)),
        shareReplay(1)
      )
    }

    this.VehicleList$.subscribe((data) => {
      this.VehicleListEventEmitter.emit(data)
    })
  }

  private formatVehicles(request: VehicleRequest): VehicleRequest {
    const newListVehicles: VehicleModel[] = []

    request.results.map((element) => {
      if (element) {
        const newVehicle: VehicleModel = {
          ...element
        }
        newListVehicles.push(newVehicle)
      }
    })


    request.results = newListVehicles

    return request
  }

  public saveNewVehicle(
    vehicle: VehicleModel
  ): Observable<VehicleModel> {
    return this._httpClient.post<VehicleModel>(this.url, vehicle)
  }

  public updateVehicle(
    id: string,
    vehicle: VehicleModel
  ): Observable<VehicleModel> {
    return this._httpClient.put<VehicleModel>(
      `${this.url}/${id}`,
      vehicle
    )
  }

  public deleteVehicle(id: string) {
    return this._httpClient.delete(`${this.url}/${id}`)
  }

  public getFullListVehicles(): Observable<any> {

    return this._httpClient
    .get<VehicleRequest>(this.url +'/lista',)
  }

  public getServiceOrdersVehicle (id: number | null): Observable<ServiceOrder[]> {    
    return this._httpClient.get<ServiceOrder[]>(`${this.url}/service-orders/${id}`);
  }
}
