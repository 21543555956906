import { Router } from '@angular/router'
import { catchError, ObservableInput, Subject, takeUntil } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
  faEyeSlash,
  faEye,
  faQuestion,
  faExclamationCircle,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { UserLoginModel } from '../../../user'
import { AuthService } from '../../../auth.service'
import { ReturnModalComponent } from 'src/app/shared/return-modal/return-modal.component'
import { ModalConfig } from 'src/app/shared/return-modal/return-modal.config'
import { setItemInLocalStorage } from '../../../../../shared/utils/localStorageFunctions';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  faEye = faEye
  faEyeSlash = faEyeSlash
  faExclamation = faExclamationCircle


  loginForm!: FormGroup

  @Input()
  unsub: Subject<boolean> = new Subject()

  submitLoginForm = false
  showPasswordControl = false
  isAdmin: boolean = false

  // Modal config
  @ViewChild('modal') private readonly modalComponent!: ReturnModalComponent
  modalMessage: string = ''
  modalConfig: ModalConfig = {
    modalTitle: '',
    modalIcon: faQuestion,
    closeButtonLabel: 'FECHAR',
    hideDismissButton: true
  }

  constructor(
    private _authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])]
    })
  }

  ngOnInit(): void {
  }

  showPassword(): void {
    this.showPasswordControl = !this.showPasswordControl
  }

  getEmailErrorMessage() {
    if (this.loginForm.controls['email'].hasError('required')) {
      return 'Email é necessário'
    }

    if (this.loginForm.controls['email'].hasError('email')) {
      return 'Digite um email válido'
    }
    return
  }

  getPasswordErrorMessage() {
    if (this.loginForm.controls['password'].hasError('required')) {
      return 'Senha é necessária'
    }

    if (this.loginForm.controls['password'].hasError('minlength')) {
      return 'Senha deve ter no mínimo 6 caracteres'
    }
    return
  }

  createObjectLogin(): UserLoginModel | null {
    if (this.loginForm.valid) {
      const loginObject: UserLoginModel = {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
      }
      return loginObject
    } else {
      return null
    }
  }

  sendLoginRequest(userLogin: UserLoginModel) {
    this._authService
      .login(userLogin)
      .pipe(
        takeUntil(this.unsub),
        catchError((error: any) => {

          return this.handleOpenModal(
            'Erro',
            'Erro ao fazer login',
            faExclamationCircle
          )
        })
      )
      .subscribe((data) => {
        if (data) {
          this._authService.token = data.access_token
          this._authService.handleSaveProfile(data.access_token)

          if(this._authService.isAdmin()){
            this.router.navigateByUrl('/admin')
          }
          else{
            setItemInLocalStorage("isLogging", "true");
            this.router.navigateByUrl('/dashboard')
          }
        }
      })
  }

  submit(): void {
    this.submitLoginForm = true

    const userForLogin = this.createObjectLogin()

    if (userForLogin) {
      this.sendLoginRequest(userForLogin)
    }
  }

  
  handleForgotPassword(): void {
    this.router.navigateByUrl('/auth/recover')
  }

  async handleOpenModal(
    title: string,
    message: string,
    icon?: IconDefinition
  ): Promise<void> {
    this.modalMessage = message
    this.modalConfig.modalTitle = title
    if (icon !== undefined) {
      this.modalConfig.modalIcon = icon
    }
    await this.modalComponent.open()
  }
}
