import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, BrowserAnimationsModule, NgxSpinnerModule, MatProgressSpinnerModule,],
  exports: [LoaderComponent]
})
export class LoaderModule {}
