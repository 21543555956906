<toolbar (onToggleMenuButton)="sideBar.toggleSideBar()" [hidden]="!isPhone"></toolbar>

<section [ngClass]="{'desktop-body': !isPhone}" >

        <app-sidebar *ngIf="!this.authService.isAdmin()"  #sidebar>
            <ng-content>
            </ng-content>
        </app-sidebar>

</section>

