import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { EventEmitter, Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { AccountModel, IAsaasAccountStatus, ParamsConfig } from './account'
import { MunicipalService } from './account-detail/commercial-information/services-data/services.component'
import { IAccountOnboarding } from './account'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private accountList$: Observable<any> | null = null
  public accountListEventEmmiter: EventEmitter<any> =
    new EventEmitter()
  public accountToEdit: any = {} 



  constructor(
    private httpClient: HttpClient
    ) {}



  public clearCacheAccountList(): void {
    this.accountList$ = null
    this.getAllAccounts()
  }

  public getAllAccounts(objParams?:ParamsConfig): void {

    if(objParams) {

      const params = new HttpParams()
      .set('page', objParams.page)
      .set('page_size', objParams.page_size)
      .set('term', objParams.term)
      .set('tags', objParams.filter?.join(","));

      this.accountList$ = this.httpClient
        .get<any>(environment.apiUrl + '/admin/contas', {params})


    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      this.accountList$ = this.httpClient
        .get<any>(environment.apiUrl + '/admin/contas', {params})

    }


    this.accountList$.subscribe((data) => {
      this.accountListEventEmmiter.emit(data)
    })
  }


  public getAccount(id: number) {

    return this.httpClient
        .get<any>(environment.apiUrl + '/admin/'+id);
  }


  public deleteAccount(id: string) {
    return this.httpClient.delete<any>(
        environment.apiUrl + '/admin/' + id
    )
  }

  public toggleAccountActivation(id: number, action:string) {
    return this.httpClient.put<any>(
        environment.apiUrl + '/admin/toggle-activation/' + id, {action}
    )
  }

  public saveNewAccount(account: AccountModel) {
    return this.httpClient.post<AccountModel>(
      environment.apiUrl + '/admin/contas',
      account
    )
  }

  public editAccount(account: AccountModel, idAccount: string) {
    return this.httpClient.put<AccountModel>(
      environment.apiUrl + '/admin/' + idAccount,
      account
    )
  }

  public getCustomerBankAccount(id: any): Observable<any> {
    return  this.httpClient.get<any>(environment.apiUrl + '/admin/banco/' + id)
  }

  public updateCustomerBankAccount(id: any, bankModel:any): Observable<any> {
    return  this.httpClient.put<any>(environment.apiUrl + '/admin/banco/' + id, bankModel )
  }  

  public getUsersList(id: number) {

    return this.httpClient
        .get<any>(environment.apiUrl + '/admin/usuarios/'+id);
        
  }


  saveNewUser(user:any){
    return this.httpClient
    .post<any>(environment.apiUrl + '/admin/user', user);
  }

  editUser(userModel:any, id:number) {
    return this.httpClient
    .put<any>(environment.apiUrl + '/admin/user/'+id, userModel);
  }
  
  deleteUser(id: number) {
    return this.httpClient.delete<any>(
        environment.apiUrl + '/admin/user/'+id)

  }

  getMunicipalServicesOptions(AccountId:any): Observable<any>  {
    return this.httpClient
        .get<any>(environment.apiUrl + '/admin/options-services/'+AccountId )
  }

  getTaxInformations(AccountId:any): Observable<any>  {
    return this.httpClient
        .get<any>(environment.apiUrl + '/admin/tax-information/'+AccountId )
  }

  updateTaxInformations(accountId:any, bodyReq:any): Observable<any>  {
    console.log(accountId ,bodyReq)
    return this.httpClient
        .post<any>(environment.apiUrl + '/admin/tax-information/'+accountId, bodyReq)
  }

  getVisualIdentityInfo(id:any){
    return this.httpClient
    .get<any>(environment.apiUrl + '/admin/visual-info/'+id, );
  }

  saveVisualIdentitySettings(settings:any, id:any){
    return this.httpClient
    .post<any>(environment.apiUrl + '/admin/visual-identity/'+id, settings);
  }

  getVisualIdentitySettings(id:any){
    return this.httpClient
    .get<any>(environment.apiUrl + '/admin/visual-identity/'+id);
  }

  public saveNewAccountService(service: MunicipalService) {
    return this.httpClient.post<any>(
      environment.apiUrl + '/admin/services',
      service
    )
  }

  public removeAccountService(id: any) {
    return this.httpClient.delete<any>(
      environment.apiUrl + '/admin/services/'+id
    )
  }

  public getAccountServices(id:string) {
    return this.httpClient.get<any>(
      environment.apiUrl + '/admin/services/'+id
    )
  }

  public getServices (): any {
    return this.httpClient.get(environment.apiUrl + '/admin/services')
  }


  public onboardingAsaasAccount(idAccount: string): Observable<IAccountOnboarding> {
   return this.httpClient
   .get<IAccountOnboarding>(`${environment.apiUrl}/admin/onboarding-asaas/${idAccount}`) 
  }

  public asaasAccountStatus(idAccount: string): Observable<IAsaasAccountStatus> {
    return this.httpClient
    .get<IAsaasAccountStatus>(`${environment.apiUrl}/admin/asaas-account-status/${idAccount}`) 
   }

  public masterAccess(id:number) {
    return this.httpClient.get<any>(environment.apiUrl + '/admin/master_access/'+id)
  }

  
  public syncAccount (id: number,data: {user:string, pw: string}): any {
    return this.httpClient.post(environment.apiUrl + '/admin/sync/'+id, data)
  }

  public getIntegrationStatus(id:number) {
    return this.httpClient.get<any>(environment.apiUrl + '/admin/sync/status/'+id)
  }

  public updateLegado(newLegado: string, accountId: number): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + `/admin/contas/${accountId}?legado=${newLegado}`)
  }

}
