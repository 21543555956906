<div class="bottom-sheet-body">

    <div class="close-icon-container" (click)="next()">
        <mat-icon class="close-icon" inline=true svgIcon="close-tab-icon" mat-list-icon >
        </mat-icon>
    </div>

    <img class="error-image" src="../../../../assets/icons/error.png" alt="error image"/>
    <p class="text-center">
        {{ errorMessage ? errorMessage : 'Erro no servidor, tente novamente!'}}
        </p>

    <button mat-raised-button color="primary" (click)="next()">OK</button>

</div>