<div class="card">
    <div>
        <div class="detail-card">
            <span class="simple-text">Nome: </span>
            <span class="bold-text">{{user.nome}}</span> 
        </div>
        <div class="detail-card">
            <span class="simple-text">E-mail: </span>
            <span class="bold-text">{{user.email}}</span> 
        </div>
        <div class="detail-card">
            <span class="simple-text">Senha: </span>
            <span class="bold-text"> ******** </span> 
        </div>
    </div>
    <div class="btn-container">
        <button mat-raised-button color="primary" (click)="handleEditClick()">Editar</button>
    </div>
</div>