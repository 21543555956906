import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ToolBarConfig } from 'src/app/shared/header/toolbar/toolbar'
import { ServiceOrderService } from '../service-order.service'
import { getIconForServiceOrder } from '../helpers'
import { faFolder, faListUl } from '@fortawesome/free-solid-svg-icons'
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component'
import { ParamsConfig } from '../../admin/account'
import { PageEvent } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { CreateServiceOrderComponent } from '../create-service-order/create-service-order.component'





@Component({
  selector: 'app-list-service-order',
  templateUrl: './list-service-order.component.html',
  styleUrls: ['./list-service-order.component.scss']
})
export class ListServiceOrderComponent extends BaseComponentComponent implements OnInit {
  headerConfig: ToolBarConfig = {
    headerTitle: 'Ordem de Serviço',
    ctaHeaderLabel: '+ Adicionar OS',
    ctaAction: () => this.handleOpenModalAddServiceOrder()
  }

  faIconTodos = faFolder
  faServiceOrder = faListUl

  ordersListParams: ParamsConfig = {
    page: 1 ,
    page_size: 12,
    filter: [],
    term:'',
  }


  public totalRecords: number = 0;

  public pageSize: number = 10;

  filterTags = ['all', 'waiting','running', 'finished','canceled']
  selectedMenu: number = 0

  public radioGroupForm: FormGroup = {} as FormGroup
  serviceOrdersList: any = []

  constructor(
    private _serviceOrderService: ServiceOrderService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,  ) {
    super()
    this.radioGroupForm = this.formBuilder.group({
      status: [0]
    })
  }

  ngOnInit(): void {
    this.getServiceOrderList()
    var d = new Date()
    var n = d.getTimezoneOffset();
  }

  getServiceOrderList() {
    this._serviceOrderService.serviceOrders.subscribe((data) => {
      this.serviceOrdersList = data
    })

    this._serviceOrderService.getAllServiceOrders(this.ordersListParams)
  }

  handleSearchChange(event:any) {
    if(this.ordersListParams.term.length > 2 || this.ordersListParams.term.length == 0) {
      this._serviceOrderService.getAllServiceOrders(this.ordersListParams)
    }
  }

  onPageChange(event: PageEvent){
    this.ordersListParams.page = event.pageIndex + 1

    this._serviceOrderService.getAllServiceOrders(this.ordersListParams)
  }

  handleOpenModalAddServiceOrder() {
    const dialogRef = this.dialog.open(CreateServiceOrderComponent,
      {
        panelClass: 'rounded-dialog'
      }
    );

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onGetIcons(status: number) {
    return getIconForServiceOrder(status)
  }

  clearFilters():void {
    this.ordersListParams = {
      page: 1 ,
      page_size: 12,
      filter: [],
      term:'',
    }
  }


  handleSelectFilter(status: number) {
    this.selectedMenu = status
    this.clearFilters()
    let tags = this.ordersListParams.filter

    tags.length = 0
    tags.push(String(status))
    this.getServiceOrderList()
    
  }


}
