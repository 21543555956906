import { FormControl, FormGroup, Validators } from '@angular/forms'
import { servicesVersion } from 'typescript'
import { PreviewColorTheme } from '../admin/account-detail/visual-identity/visual-Identity'
import { CustomerModel } from '../customer/customer'
import { internServiceModel, InvoiceModel } from '../invoice/invoice'

export * from './bottom-sheet'
export * from './error-bottom-sheet'

export interface ErrorObj {
  redirectTo: string
  error: any
}

export function createObjectToEditCustomer(
  customerForm: FormGroup, AdressForm: FormGroup
): CustomerModel {

  const objCreateCustomer: any = {
    nome: customerForm.controls['name'].value,
    cpf_cnpj: customerForm.controls['cpfCnpj'].value,
    email: customerForm.controls['email'].value,
    whatsapp: customerForm.controls['phoneWhatsapp'].value,
    telefone: customerForm.controls['phoneFix'].value,
    ativo: true,
    cep: AdressForm.controls['cep'].value,
    endereco: AdressForm.controls['address'].value,
    endereco_num: AdressForm.controls['numberAddress'].value,
    bairro: AdressForm.controls['neighbourAddress'].value,
    complemento: AdressForm.controls['complementAddress'].value,
    cidade: AdressForm.controls['city'].value,
    estado: AdressForm.controls['state'].value,

  }

  return objCreateCustomer
}


export function setTablePropertys(isPhone: Boolean, list: Array<string>): Array<string> {

  if(isPhone) {
    let newlist = list.filter( (item) => item !== 'description' )

    return newlist

  }else{

    let newlist = ['data', 'transaction', 'description', 'value', 'balance']
    return newlist

  };

}

 function invoiceDateHandler(form: FormGroup, ChargeDueDate:any): string {

  const now = new Date();
  const dueDate = new Date(ChargeDueDate)
  let date = ''

  switch (form.controls['creationDate'].value) {
    case "1":
    //option: now
      date = now.toLocaleString('af-ZA', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).replace(/\//g, '-')
      break;
    case "2":
    //option: dueDate
    date = ChargeDueDate
      break;
    case "3":
      //option: X days after dueDate
       dueDate.setDate(dueDate.getDate() - form.controls['selectedDate'].value)
       date = dueDate.toLocaleString('af-ZA', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).replace(/\//g, '-')
      break;

    case "4":
      //option: at the first day of duedate's next month
      dueDate.setMonth(dueDate.getMonth() + 2)
      date = dueDate.toLocaleString('af-ZA', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).replace(/\//g, '-')
      break;

      default: date = 'erro'
  }

  return date
 }

 function DateJsToString(date:Date): string {
  function padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
  }

  return [
    date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())
  ].join('-');
}

export function objectInvoiceBuilder(
  invoiceForm: FormGroup, service: internServiceModel, description: string, charge?:any): InvoiceModel {

  if(
    invoiceForm.controls['creationDate'] &&
    invoiceForm.controls['creationDate'].value !== "3"){
    invoiceForm.controls['selectedDate'].setValue('')
  }

  const totalTaxes: number =
  Number(invoiceForm.controls['valueCofins']?.value)  +
  Number(invoiceForm.controls['valueCsll']?.value)  +
  Number(invoiceForm.controls['valueInss']?.value)  +
  Number(invoiceForm.controls['valueIr']?.value)  +
  Number(invoiceForm.controls['valuePis']?.value) +
  Number(invoiceForm.controls['others']?.value) +
  (invoiceForm.controls['configIss'].value === true ? Number(invoiceForm.controls['issPercent']?.value) / 100 * charge.value : 0 )

  const objBuildInvoice: InvoiceModel = {
    payment: charge ? charge.id : null,
    installment: null,
    serviceDescription: description,
    observations: invoiceForm.controls['textObs'].value,
    value: charge? charge.value : invoiceForm.controls['invoiceValue'].value,
    deductions: totalTaxes,
    effectiveDate: charge ? invoiceDateHandler(invoiceForm,  charge.dueDate) : DateJsToString(invoiceForm.controls['selectedDate'].value),
    externalReference: charge ? charge.id : null,
    taxes: {
      retainIss: invoiceForm.controls['configIss'].value === "sim" ? true : false,
      iss:  service.issTax,
      cofins: invoiceForm.controls['valueCofins'].value | 0,
      csll: invoiceForm.controls['valueCsll'].value | 0,
      inss: invoiceForm.controls['valueInss'].value | 0,
      ir: invoiceForm.controls['valueIr'].value | 0,
      pis: invoiceForm.controls['valuePis'].value | 0
    },
    municipalServiceId: service.serviceId || null,
    municipalServiceCode: service.municipalServiceCode,
    municipalServiceName: service.description
  }

  if(!charge) {
    objBuildInvoice.customer = invoiceForm.controls['customer'].value
  }
  return objBuildInvoice
}

export const companyTypes: Array<any> = [

  {
    view: 'MEI',
    original: 'MEI'
  },
  {
    view: 'LIMITADA',
    original: 'LIMITED'
  },
  {
    view: 'INDIVIDUAL',
    original: 'INDIVIDUAL'
  },
  {
    view: 'ASSOCIAÇÃO',
    original: 'ASSOCIATION'
  }
]

//colors to set asaas charge preview
export const AsaasColorThemes : PreviewColorTheme[] = [
  { name: 'deep-blue',
    primary: '#0030B9',
    secondary: '#001074' ,
    text: 'white'
  },
  { name: 'white',
    primary: '#FFFFFF',
    secondary: '#EEEEEE' ,
    text: 'black'
  },
  { name: 'light-blue',
    primary: '#1DA9DA',
    secondary: '#007AB4' ,
    text: 'white'
  },
  { name: 'pink',
  primary: '#ff4E7A',
  secondary: '#D62B5D' ,
  text: 'white'
  },
  { name: 'light-gray',
    primary: '#EEEEEE',
    secondary: '#FFFFFF' ,
    text: 'black'
  },
  { name: 'green',
    primary: '#00D071',
    secondary: '#00B865' ,
    text: 'white'
  },
  { name: 'brown',
    primary: '#C5381A',
    secondary: '#AB3116' ,
    text: 'white'
  },
  { name: 'deep-yellow',
    primary: '#FF9A0A',
    secondary: '#E68A09' ,
    text: 'white'
  },
  { name: 'yellow',
    primary: '#FFD33F',
    secondary: '#E6BD39' ,
    text: 'black'
  },
  { name: 'purple',
    primary: '#8D72CF',
    secondary: '#735DA8' ,
    text: 'white'
  },
  { name: 'deep-gray',
  primary: '#B0B0B0',
  secondary: '#969696' ,
  text: 'white'
  },
  { name: 'black',
    primary: '#333333',
    secondary: '#000000' ,
    text: 'white'
  }
]

