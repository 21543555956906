import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightLetters'
})
export class HighlightLettersPipe implements PipeTransform {
  transform(value: string, term: string): string {
    if (!term) return value.toLocaleUpperCase();
    return value.replace(new RegExp(term, 'gi'), match => `<mark>${match}</mark>`);
  }
}
