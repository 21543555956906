import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbAlertModule
} from '@ng-bootstrap/ng-bootstrap'

import { ListServiceOrderComponent } from './list-service-order/list-service-order.component'
import { HeaderModule } from 'src/app/shared/header/header.module'
import { CardServiceOrderComponent } from './components/card-service-order/card-service-order.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { InfoCardServiceOrderComponent } from './components/info-card-service-order/info-card-service-order.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CreateServiceOrderComponent } from './create-service-order/create-service-order.component'
import {MaterialExampleModule} from "../../../material.module";
import { AgilwebFilterModule } from 'src/app/shared/agilweb-filter/agilweb-filter.module'
import { ServiceOrderComponent } from './service-order.component'
import { PipesModule } from 'src/app/shared/pipes/pipes.module'
import { NgxCurrencyModule } from 'ngx-currency'
import { ServiceOrderRoutingModule } from './service-order-routing.module'
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ChecklistComponent } from './components/checklist/checklist.component'

@NgModule({
  declarations: [
    ListServiceOrderComponent,
    CardServiceOrderComponent,
    InfoCardServiceOrderComponent,
    CreateServiceOrderComponent,
    ServiceOrderComponent,
    ChecklistComponent,
  ],
  imports: [
    PipesModule,
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbAlertModule,
    ServiceOrderRoutingModule,
    HeaderModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialExampleModule,
    AgilwebFilterModule,
    NgxCurrencyModule,
    DirectivesModule,
  ],
  exports: [ChecklistComponent]
})
export class ServiceOrderModule {}
