import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Observable, shareReplay } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ParamsConfig } from '../admin/account'

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderService {

  serviceOrderList$: Observable<ServiceOrder[]> | null = null
  public serviceOrders: EventEmitter<ServiceOrder[]> = new EventEmitter()

  constructor(private _http: HttpClient) {}


  public clearCacheServ(): void {
    this.serviceOrderList$ = null
    this.getAllServiceOrders()
  }

  getAllServiceOrders(orderParams?:ParamsConfig) {
    let params

    if(orderParams) {
      const search = orderParams.term  || ''
      const tag = orderParams.filter[0] || ''

      params = new HttpParams()
      .set('page', orderParams.page)
      .set('page_size', orderParams.page_size)
      .set('term', search)
      .set('tag', tag)

    }else {

       params = {
        page: 1,
        page_size: 12
      }
    }

    this.serviceOrderList$ = this._http
    .get<any>(environment.apiUrl + '/orders', {params})

    this.serviceOrderList$.subscribe((data) => {
      this.serviceOrders.emit(data)
    })
  }

  getOsFiles(order:any, externToken?:string|null) {
    let headers = new HttpHeaders();

    if (externToken){
      headers = headers.set(
        'Authorization',
        'Bearer ' + externToken
      )
    }

    return this._http.get(
        environment.apiUrl + '/orders/checklist/'+order, {headers}
    )
  }

  createOrder(order:any) {
    return this._http.post(
      environment.apiUrl + '/orders',
      order
    )
  }
  editOrder(order: any) {
    return this._http.put(
        environment.apiUrl + '/orders',
        order
    )

  }

  editServiceOrder(idOrder: number, serviceOrder: ServiceOrder) {
    return this._http.put(
      environment.apiUrl + '/orders/' + idOrder,
      serviceOrder
    )
  }

  deleteServiceOrder(idOrder: number) {
    return this._http.delete(environment.apiUrl + '/orders/' + idOrder)
  }

  changeStatus(idOrder: number, newStatus: number, externToken?: string | null) {
    let headers = null;
    let reqBody = {newStatus: newStatus}

    if (externToken) {
      headers = new HttpHeaders().set(
        'Authorization',
        'Bearer ' + externToken
      );
    }

    if(externToken && headers ) {

      return this._http.put(environment.apiUrl + '/orders/status/' + idOrder, reqBody, {headers});

    }else{ 
      return this._http.put(environment.apiUrl + '/orders/status/' + idOrder, reqBody);
    }

  }

  newcheckList(formData:FormData, osId:number, externToken?:string|null):Observable<any> {
    let headers = new HttpHeaders();

    if (externToken){
      headers = headers.set(
        'Authorization',
        'Bearer ' + externToken
      )
    }

    if(externToken) {
      console.log('tem headers')
      return this._http.post(environment.apiUrl + '/orders/checklist/' + osId, formData, {headers})
    }else {
      return this._http.post(environment.apiUrl + '/orders/checklist/' + osId, formData)
    }
  }

  getServiceOrderById(id: number): Observable<any> {
    return this._http.get(environment.apiUrl + '/orders/'+id)
  }
  
  deleteOsFile(fileName:string, osId:number) {

    const params = new HttpParams()
    .set('file', fileName)

    return this._http.delete(
        environment.apiUrl + '/orders/checklist/'+osId, {params}
    )
  }
}
