import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { AdminService } from '../../admin/admin.service';
import { SucessBottomSheetComponent } from '../../helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import { ErrorBottomSheet } from '../../helpers';
import { UserModel } from '../profile/user';

@Component({
  selector: 'app-users-settings',
  templateUrl: './users-settings.component.html',
  styleUrls: ['./users-settings.component.scss']
})
export class UsersSettingsComponent extends BaseComponentComponent implements OnInit {

  deleteEventEmitter = EventEmitter
  subscription = new Subscription

  @ViewChild('modalDeleteClient')
  private modalDeleteClient!: TemplateRef<any>

  idClientToDelete : string = ''
  userToEdit: any

  userData:Array<UserModel> = []
  @ViewChild('modalAddUser')
  private modalAddUser!: TemplateRef<any>

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _adminService: AdminService,
    private readonly modalService: NgbModal,
  ) { 
    super()
  }

  @Input()
  user!: any
  
  showCreateComponent: boolean = false
  showEditComponent: boolean = false

  hideCreateComponent(reload?:any): void {
    this.showCreateComponent = !this.showCreateComponent

    if(reload)
    this.getClientsList(this.user.cliente_conor_id)
  }

  hideEditComponent(reload?:any): void {
    this.showEditComponent = !this.showEditComponent

    if(reload)
    this.getClientsList(this.user.cliente_conor_id)
  }

  createButtonHandler(): void {
    this.showCreateComponent = true
    this.showEditComponent = false
  }

  editButtonHandler(user:any): void {
    this.userToEdit = user
    this.showEditComponent = true
    this.showCreateComponent = false
  }

  

  getClientsList(event?:any): void {
    this._adminService.getUsersList(Number(this.user.cliente_conor_id)).subscribe((data:any)=>{
      this.userData = this.removeLoggedUserFromList(data)
    })
  }

  handleDeleteUser(id:any) {

    this._adminService.deleteUser(id).subscribe({
            next: value => {

                this._bottomSheet.open(SucessBottomSheetComponent,
                    {
                        data: 'Usuário removido !'
                    }
                );


                this.getClientsList(this.user.cliente_conor_id)


            },
            error: err => {
                this._bottomSheet.open(ErrorBottomSheet,
                    {
                        data:
                            {
                                error: err
                            }
                    }
                );


            }
        }
    )


  
  }

  removeLoggedUserFromList(data: UserModel[]){
    let filteredList = data.filter(user => user.id !== this.user.id)
    return filteredList
  }

  ngOnChanges(): void{
    if (this.user) {
      this.getClientsList(this.user.cliente_conor_id)
    }
  }

  ngOnInit(): void {



     
  }

}
