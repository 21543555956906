<mat-drawer-container  class="conteiner-desktop">
    <mat-drawer disableClose #drawer [ngClass]="{'sidenav-desktop': !isPhone}" [mode]="isPhone? 'push': 'side'" [opened]="!isPhone">
        <div class="navigation-list" [ngClass]="{'navigation-list-desktop': !isPhone}">

            <mat-nav-list  class="nav" role="navigation">

                <mat-list-item *ngIf="!isPhone">
                    <img
                    (click)="goToHomePage()"
                    src="../../../../assets/images/logo/logo-agilweb-black-orange.svg"
                    alt="Agilweb logo image"
                    class="logo">
                </mat-list-item>

                <mat-list-item class="mt-2" routerLink="/profile" [routerLinkActive]="['is-active']"(click)="handleRedirectTo('profile')">
                    <mat-icon mat-list-icon>account_circle</mat-icon>
                    <span mat-line>Meu Perfil</span>
                </mat-list-item>

                <mat-list-item
                userRole permission="{{accessControl.ROLE_PANEL_READ}}"
                class="second-item"
                routerLink="/dashboard"
                [routerLinkActive]="['is-active']">

                    <span mat-list-icon  class="material-icons">dashboard</span>
                    <span mat-line>Painel</span>
                </mat-list-item>

                <mat-list-item
                userRole permission="{{accessControl.ROLE_CUSTOMERS_READ}}"
                routerLink="/customers"
                [routerLinkActive]="['is-active']">

                    <mat-icon mat-list-icon>person</mat-icon>
                    <span mat-line>Clientes</span>
                </mat-list-item>

                <mat-accordion
                    userRole permission="{{accessControl.ROLE_FINANCIAL_MENU_READ}}">
                    <mat-expansion-panel hideToggle [expanded]="true">
                    <mat-expansion-panel-header
                        [ngClass]="{'is-active': chargesActive.isActive ||
                        statementActive.isActive ||
                        dunningsActive.isActive || productsActive.isActive}">

                        <mat-panel-title>
                            <span mat-list-icon  class="material-icons">attach_money</span>
                            <span mat-line>Financeiro</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_CHARGES_READ}}"
                        routerLink="/charges"
                        [routerLinkActive]="['sub-menu-active']" #chargesActive="routerLinkActive">
                            <span mat-list-icon  class="material-icons">price_check</span>
                            <span mat-line>Cobranças</span>
                        </mat-list-item>

                        <mat-list-item
                            userRole permission="{{accessControl.ROLE_STATEMENT_READ}}"
                            #statementActive="routerLinkActive" routerLink="/statement" [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">
                                currency_exchange
                                </span>
                            <span mat-line>Extrato</span>
                        </mat-list-item>

                        <mat-list-item
                        accountBreakpointVersion menuCheck=true
                        userRole permission="{{accessControl.ROLE_STATEMENT_READ}}"
                        #productsActive="routerLinkActive" 
                        routerLink="/products" 
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons"> shopping_bag </span>
                            <span mat-line>Produtos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_DUNNINGS_READ}}"
                        #dunningsActive="routerLinkActive"
                        routerLink="/dunnings"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon class="material-icons-round"> do_not_disturb_on </span>
                            <span mat-line>Negativações</span>
                        </mat-list-item>
                    </mat-expansion-panel>

                </mat-accordion>

                <mat-accordion
                    userRole permission="{{accessControl.ROLE_DEVICES_READ}}">
                    <mat-expansion-panel hideToggle [expanded]="true">
                    <mat-expansion-panel-header
                        [ngClass]="{'is-active': devicesActive.isActive ||
                        vehiclesActive.isActive ||
                        OSActive.isActive ||
                        technicians.isActive || reports.isActive }">
                        <mat-panel-title>
                            <span mat-list-icon  class="material-icons">engineering</span>
                            <span mat-line>Operacional</span>
                            <span class="new" mat-line>NOVO</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_CHARGES_READ}}"
                        routerLink="/devices"  #devicesActive="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">satellite_alt</span>
                            <span mat-line>Dispositivos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_VEHICLES_READ}}"
                        routerLink="/vehicles" #vehiclesActive="routerLinkActive"  [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons">directions_car</span>
                            <span mat-line>Veículos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_READ}}"
                        routerLink="/ordem-servico"
                        #OSActive="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">description</span>
                            <span mat-line>Ordem<br/> de serviço</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_TECHNICIANS_READ}}"
                        routerLink="/tecnicos"
                        #technicians="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons">manage_accounts</span>
                            <span mat-line> Técnicos </span>
                        </mat-list-item>


                        <mat-list-item
                        routerLink="/relatorios"
                        #reports="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons"> bar_chart </span>
                            <span mat-line> Relatórios </span>
                        </mat-list-item>

                    </mat-expansion-panel>

                </mat-accordion>




<!--                <mat-list-item routerLink="/charges" [routerLinkActive]="['is-active']">-->
<!--                    <span mat-list-icon  class="material-icons">attach_money</span>-->
<!--                    <span mat-line>Cobranças</span>-->
<!--                </mat-list-item>-->

                <!-- <mat-list-item routerLink="/invoice" [routerLinkActive]="['is-active']">
                    <mat-icon mat-list-icon class="material-icons-round"> description </mat-icon>
                    <span mat-line> Notas Fiscais </span>
                </mat-list-item> -->


                <!--
                <mat-list-item routerLink="/admin" [routerLinkActive]="['is-active']">
                    <mat-icon mat-list-icon class="material-icons-round">
                            admin_panel_settings
                    </mat-icon>
                    <span mat-line> Contas </span>
                </mat-list-item>
                -->

                <mat-list-item (click)="handleLogout()" class="last-item" >
                    <mat-icon mat-list-icon class="material-icons-round">logout</mat-icon>
                    <span mat-line>Sair</span>
                </mat-list-item>

<!--                <mat-list-item outerLink="/dashboard" [routerLinkActive]="['is-active']" >-->
<!--                    <mat-icon mat-list-icon>description</mat-icon>-->
<!--                    <span mat-line>Relatórios</span>-->
<!--                </mat-list-item>-->

            </mat-nav-list>

        </div>



    </mat-drawer>

    <mat-drawer-content [ngClass]="{'desktop-content': !isPhone,   'phone-content' : isPhone}" >
        <ng-content></ng-content>

    </mat-drawer-content>
</mat-drawer-container>
