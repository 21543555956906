import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'
import {ToolBarConfig} from './toolbar'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],

})
export class ToolbarComponent implements OnInit {
  faSearch = faSearch

  BaseUrl = environment.apiUrl

  @Input() headerConfig: ToolBarConfig = {} as ToolBarConfig

  constructor(
    private router: Router,
  ) {


  }
  @Output() onToggleMenuButton = new EventEmitter<void>();



  handleAppBarMenuButton (): void {

  this.onToggleMenuButton.emit();


  }

  goToHomePage(): void {

    this.router.navigateByUrl('/')
    
  }

  handleShowManual(): void {
    this.router.navigateByUrl('dashboard/help')
  }

  ngOnInit(): void {


  }



}
