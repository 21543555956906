import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable, shareReplay } from 'rxjs'
import { EventEmitter } from '@angular/core'
import { ChipModel, ChipRequest, ChipParams } from './chip'
import { TelefonePipe } from 'src/app/shared/pipes/telefone.pipe'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ChipService {
  constructor(
    private _httpClient: HttpClient,
    private _phonePipe: TelefonePipe
  ) {}

  private url = environment.apiUrl + '/chips'
  private chipList$: Observable<ChipModel[]> | null = null
  public total_records : number = 0
  public chipToEdit: ChipModel = {} as ChipModel
  public chipToDelete:number | null = null
  public chipListEventEmitter: EventEmitter<ChipModel[]> =
    new EventEmitter()

  private formatChip(list: ChipModel[]): ChipModel[] {
    const newListChip: ChipModel[] = []

    list.map((element) => {
      const newChip: ChipModel = {
        ...element
      }
      newListChip.push(newChip)
    })

    return newListChip
  }

  public getAllChips(objParams?:ChipParams) {

    let request
    if(objParams) {

      const params = new HttpParams()
      .set('page', objParams.page)
      .set('page_size', objParams.page_size)
      .set('term', objParams.term)
      .set('tags', objParams.filter?.join(","));


      request = this._httpClient
      .get<ChipRequest>(this.url, {params})
    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      request = this._httpClient
      .get<ChipRequest>(this.url, {params})

    }

    
    request.subscribe(res => {
      this.total_records = res.total_records }
    )

    this.chipList$ = request.pipe(
      map((data) => this.formatChip(data.results)),
      shareReplay(1)
    )
    
    this.chipList$.subscribe((data) => {
      this.chipListEventEmitter.emit(data)
    })

  }

  public clearChipCache() {
    this.chipList$ = null
    this.getAllChips()
  }

  saveNewChip(chip: Omit<ChipModel, 'id' >) {
    return this._httpClient.post<ChipModel>(this.url, chip)
  }

  editChip(chip: ChipModel) {
    return this._httpClient.put<ChipModel>(this.url+'/'+chip.id, chip)
  }

  deleteChip(id: number) {
    return this._httpClient.delete<ChipModel>(
      `${this.url}/${id}`
    )
  }

  public getFullListChip():  Observable<any> {

    return this._httpClient
    .get<ChipModel>(this.url+'/lista')
  
  }

  public getInfoOsChipById(id: number): Observable<ServiceOrder[]> {
    return this._httpClient.get<ServiceOrder[]>(`${this.url}/chip-orders/${id}`);
  };
}
