import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CepPipe } from './cep.pipe'
import { CpfCnpjPipe } from './cpf-cnpj.pipe'
import { TelefonePipe } from './telefone.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SearchFilterVehiclePipe } from './search-filter-vehicle.pipe';
import { SearchFilterChipPipe } from './search-filter-chip.pipe';
import { SearchFilterEquipmentPipe } from './search-filter-equipment.pipe';
import { ChargeFilterPipe } from './charge-filter.pipe'

import { DateBrPipe } from './convert-date-br.pipe';
import { NameFormatPipe } from './nameFormat';
import { HighlightLettersPipe } from './highlight-letters.pipe';
import { FilterItemsPipe } from './filter-text.pipe';
import { TimezoneAdjustPipe } from './timezone-adjust.pipe'

@NgModule({

  declarations: [HighlightLettersPipe, CepPipe, CpfCnpjPipe, TelefonePipe, SearchFilterPipe, SearchFilterVehiclePipe, SearchFilterChipPipe, SearchFilterEquipmentPipe, ChargeFilterPipe, DateBrPipe,NameFormatPipe, FilterItemsPipe, TimezoneAdjustPipe],
  imports: [CommonModule],
    exports: [HighlightLettersPipe, CepPipe, CpfCnpjPipe, TelefonePipe, SearchFilterPipe, SearchFilterVehiclePipe, SearchFilterEquipmentPipe, ChargeFilterPipe, DateBrPipe,NameFormatPipe, SearchFilterChipPipe, FilterItemsPipe, TimezoneAdjustPipe],
  providers: [CepPipe, CpfCnpjPipe, TelefonePipe, HighlightLettersPipe, FilterItemsPipe]
})
export class PipesModule {}
