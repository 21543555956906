export function translateBillingType(billingType: any) {

    var statusReturn = ""

    switch (billingType) {

        case "BOLETO" :
            statusReturn = "Boleto Bancário"
            break;
        case "CREDIT_CARD" :
            statusReturn = "Cartão de Crédito"
            break;
        case "PIX" :
            statusReturn = "Pix"
            break;
        case "UNDEFINED" :
            statusReturn = "Cliente Escolhe"
            break;
        default:
            statusReturn = billingType;

    }

    return statusReturn;

}