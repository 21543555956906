import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private chatKey: string = environment.chatKey;
  private urlGetChatInMovidesk: string = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
  private scriptLoaded: boolean = false;

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadChatScript() {
    if (!this.scriptLoaded) {
      this.addScriptToDOM();
      this.scriptLoaded = true;
    }
  }

  private addScriptToDOM() {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.text = `var mdChatClient="${this.chatKey}";`;

    const chatScriptElement = this.renderer.createElement('script');
    chatScriptElement.src = this.urlGetChatInMovidesk;

    this.renderer.appendChild(document.body, scriptElement);
    this.renderer.appendChild(document.body, chatScriptElement);
  }

  removeChat() {
    const chatWidgetElement = document.getElementById('md-app-widget');
    if (chatWidgetElement) {
      chatWidgetElement?.parentNode?.removeChild(chatWidgetElement);
    }

    this.scriptLoaded = false;
  }
}
