<section id="list-service-orders">
  <div [ngClass]="{'header-container-content-desktop': !isPhone,
  'header-container-content-phone': isPhone   
  }">


  <header id="title">
    <h1>Ordens de Serviço</h1>
  </header>

  <div class="filter-container">
    <div>
        <mat-form-field class="search-input" appearance="outline" color="accent">
            <input [(ngModel)]="ordersListParams.term" (ngModelChange)="handleSearchChange($event)"  type="search" matInput placeholder="Nome da OS">
        </mat-form-field>
    </div>
  </div>


  </div>
  <form id="filterByStatus" [formGroup]="radioGroupForm">
    <div class="divFilterByStatus"
    [ngClass]="{'phone': isPhone}">
      <div [ngClass]="{'active': selectedMenu === 0 }" (click)="handleSelectFilter(0)">
        <input
          type="radio"
          id="filterAllCheck"
          formControlName="status"
          [value]="0"
        />
        <label class="for-checkbox-tools" for="filterAllCheck">
          <fa-icon id="icon" color="white" [icon]="faIconTodos"></fa-icon>
          <span class="menu-name">Todas</span>
          
        </label>
      </div>
      <div [ngClass]="{'active': selectedMenu === 1 }" (click)="handleSelectFilter(1)">
        <input
          type="radio"
          id="filterByWaitingCheck"
          formControlName="status"
          [value]="1"
        />
        <label class="for-checkbox-tools" for="filterByWaitingCheck">
          <fa-icon  id="icon"[icon]="onGetIcons(1)"></fa-icon>
          <span class="menu-name">Em espera</span>

          
        </label>
      </div>
      <div [ngClass]="{'active': selectedMenu === 2 }" (click)="handleSelectFilter(2)">
        <input
          type="radio"
          id="filterByProgressCheck"
          formControlName="status"
          [value]="2"
        />
        <label class="for-checkbox-tools" for="filterByProgressCheck">
          <fa-icon  id="icon" [icon]="onGetIcons(2)"></fa-icon>
          <span class="menu-name">Em progresso</span>
        </label>
      </div>

      <div  [ngClass]="{'active': selectedMenu === 3}" (click)="handleSelectFilter(3)">
        <input
          type="radio"
          id="filterByFinishedCheck"
          formControlName="status"
          [value]="3"
        />
        <label class="for-checkbox-tools" for="filterByFinishedCheck">
          <fa-icon id="icon" [icon]="onGetIcons(3)"></fa-icon>
          <span class="menu-name">Finalizadas</span>
        </label>
      </div>
      <div [ngClass]="{'active': selectedMenu === 4 }"  (click)="handleSelectFilter(4)">
        <input
          type="radio"
          data-cy="filterCanceledCheck"
          id="filterCanceledCheck"
          formControlName="status"
          [value]="4"
        />
        <label class="for-checkbox-tools" for="filterCanceledCheck">
          <fa-icon id="icon" [icon]="onGetIcons(4)"></fa-icon>
          <span class="menu-name">Canceladas</span>          
        </label>
      </div>
    </div>
  </form>



</section>

<div class="c-container">

    <app-card-service-order *ngFor="let serviceOrder of serviceOrdersList.results"
    [serviceOrderCard]="serviceOrder"
  ></app-card-service-order>


</div>

<mat-paginator 
  [length]="serviceOrdersList.total_records"
  [pageSize]="ordersListParams.page_size"
  hidePageSize="true"
  (page)="onPageChange($event)"
  class="paginator"
>
</mat-paginator>

<button 
  mat-fab 
  color="primary" 
  class="agil-web-fab"
  (click)="handleOpenModalAddServiceOrder()">
    <mat-icon>add</mat-icon>
</button>


