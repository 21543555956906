import {Component, OnInit } from '@angular/core'
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component'


@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.scss'],

})
export class ServiceOrderComponent extends BaseComponentComponent implements OnInit {

  constructor(){
    super()
  }




  ngOnInit(): void  {
  }

}
