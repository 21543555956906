import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: string): string {
    if (/([0-9])/.test(value)) {
      if (value.length === 11) {
        const newValue =
          value.substring(0, 3) +
          '.' +
          value.substring(3, 6) +
          '.' +
          value.substring(6, 9) +
          '-' +
          value.substring(9, 11)
        return newValue
      } else if (value.length === 14) {
        const newValue =
          value.substring(0, 2) +
          '.' +
          value.substring(2, 5) +
          '.' +
          value.substring(5, 8) +
          '/' +
          value.substring(8, 12) +
          '-' +
          value.substring(12, 14)
        return newValue
      } else {
        throw new Error('CPF/CNPJ inválido')
      }
    } else {
      return '-'
    }
  }
}
