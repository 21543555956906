import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { ModalConfig } from './return-modal.config'

@Component({
  selector: 'app-modal',
  templateUrl: './return-modal.component.html',
  styleUrls: ['./return-modal.component.scss']
})
@Injectable()
export class ReturnModalComponent implements OnInit {
  faTimes = faTimes

  @Input()
  public modalConfig!: ModalConfig

  @ViewChild('modal')
  private readonly modalContent!: TemplateRef<ReturnModalComponent>

  private modalRef!: NgbModalRef

  constructor(public readonly modalService: NgbModal) {}

  ngOnInit(): void {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        backdrop: 'static',
        centered: true,
        modalDialogClass: 'custom-modal-content'
      })
      void this.modalRef.result.then(resolve, resolve)
    })
  }

  close(){
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
