<mat-toolbar class="header-container" >

    <div class=mat-icon-button (change)="handleAppBarMenuButton()" class="menu-container">
        <input class="checkbox" type="checkbox" id="checkbox-menu">
        <label class="label" for="checkbox-menu"> 
            <span class="underline"></span>
            <span class="underline"></span>
            <span class="underline"></span>
        </label>
    </div>

    <img 
        (click)="goToHomePage()"
        src="../../../../assets/images/logo/logo-agilweb-black-orange.svg" 
        alt="Agilweb logo image" 
        class="logo"
    >

    <button mat-icon-button  >
        <mat-icon>notifications</mat-icon>
    </button>
</mat-toolbar>



