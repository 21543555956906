import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {
  transform(value: string): string {
    if (/([0-9])/.test(value)) {
      if (value.length === 8) {
        const newValue = value.substring(0, 5) + '-' + value.substring(5, 8)
        return newValue
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }
}
