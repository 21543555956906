import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, MinValidator, Validators } from '@angular/forms'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { ServiceOrderService } from '../service-order.service'
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CustomerModel } from '../../customer/customer'
import { VehicleModel } from '../../vehicle/vehicle'
import { EquipmentModel } from '../../equipment/equipment/equipment'
import { TechnicianModel } from '../../technician/technician'
import { CustomerService } from '../../customer/customer.service'
import { VehicleService } from '../../vehicle/vehicle.service'
import { TechnicianService } from '../../technician/technician.service'
import { EquipmentService } from '../../equipment/equipment/equipment.service'
import { CepService } from 'src/app/shared/utils/handleCep.service'
import { catchError } from 'rxjs'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { SucessBottomSheetComponent } from '../../helpers/sucess-bottom-sheet/sucess-bottom-sheet.component'
import { ErrorBottomSheet } from '../../helpers'
import { ChipService } from '../../equipment/chip/chip.service'
import { ChipModel } from '../../equipment/chip/chip'
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/shared/utils/CustomDateAdapter'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

@Component({
  selector: 'app-create-service-order',
  templateUrl: './create-service-order.component.html',
  styleUrls: ['./create-service-order.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class CreateServiceOrderComponent extends BaseComponentComponent implements OnInit {

  isEdit: boolean = false;
  OrderToEdit: ServiceOrder | null = null

  faSaveFile = faSave

  createServiceOrder: FormGroup
  serviceInformations: FormGroup
  technicianForm: FormGroup
  customerForm: FormGroup

  customerList: Array<CustomerModel>
  filteredCustomerOptions:any

  equipmentList: Array<EquipmentModel>
  filteredEquipmentOptions:any

  vehiclesList: Array<VehicleModel>
  filteredVehicleOptions:any

  technicianList: Array<TechnicianModel>
  filteredTechnicianOptions:any

  chipList: Array<ChipModel>
  filteredChipOptions:any

  techSelected: TechnicianModel | '' = ''
  vehicleSelected: VehicleModel | '' = ''
  customerSelected: CustomerModel | '' = ''
  equipmentSelected: EquipmentModel | '' = ''
  chipSelected: ChipModel | '' = ''
  dateTimeScheduled: Date | string = ''
  ErrorStep: string
  value: number = 0
  minDate: Date = new Date();
  currentISOTime = this.minDate.toISOString().slice(0, -8)

  stepToControlRegister: number = 0

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ServiceOrder,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private cepService: CepService,
    private _customerService: CustomerService,
    private _vehicleService:VehicleService,
    private _technicianService:TechnicianService,
    private _equipmentService:EquipmentService,
    private _chipService: ChipService,
    private dialogRef: MatDialogRef<CreateServiceOrderComponent>,
    private _serviceOrderService: ServiceOrderService
  ) {
    super()
    this.createFormsBuilder()

    if (data) {
      this.isEdit = true
      this.OrderToEdit = data
    }


  }

  createFormsBuilder(): void {

    this.createServiceOrder = this._formBuilder.group({
      title: ['', Validators.required],
      deadline: ['', Validators.required]
    })

    this.technicianForm = this._formBuilder.group({
      technician: ['', Validators.required],
      price: [0, Validators.required]
    })

    this.serviceInformations = this._formBuilder.group({
      serviceType: ['', Validators.required],
      obs: [''],
      vehicleModel: ['', Validators.required],
      licensePlate: ['', Validators.required,],
      equipment: ['', Validators.required],
      chip: ['', Validators.required],

    })

    this.serviceInformations.controls['vehicleModel'].disable()

    this.customerForm = this._formBuilder.group({
      customerName: ['', Validators.required],
      dateTime: ['', Validators.required],
      cep: ['', Validators.required],
      address: ['', Validators.required],
      addressNumber: ['', Validators.required],
      neighbourAddress: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      complementAddress:['']//useless
    })

    this.customerForm.get('customerName')!.valueChanges.subscribe( search => {
      this.filterItens('customer', search)
    })


  }

  filterItens(type:string, search:string) {
    if( !type || !search ) return 

    switch(type) {
      case 'customer':

        this.filteredCustomerOptions = this.customerList.filter(
          (data) =>  {
    
            return data.nome
            .toLowerCase()
            .indexOf(search?.toLowerCase()) > - 1
          })
      
          break;

      case 'equipment':
        this.filteredEquipmentOptions = this.equipmentList.filter(
          (data) =>  {
    
            return data.imei
            .toLowerCase()
            .indexOf(search?.toLowerCase()) > - 1
          })
        break;

      case 'chip':
        this.filteredChipOptions = this.chipList.filter(
          (data) =>  {
    
            return data.numero_linha
            .toLowerCase()
            .indexOf(search?.toLowerCase()) > - 1
          })
        break;

      case 'vehicle':
        this.filteredVehicleOptions = this.vehiclesList.filter(
          (data) =>  {
    
            return data.placa
            .toLowerCase()
            .indexOf(search?.toLowerCase()) > - 1
          })
        break;

      case 'technician':
        this.filteredTechnicianOptions = this.technicianList.filter(
          (data) =>  {
    
            return data.nome
            .toLowerCase()
            .indexOf(search?.toLowerCase()) > - 1
          })
        break;

    }

  }

  filterTechnician(id:number): TechnicianModel  | ''  {
    let tecnico = this.technicianList
    .filter( tech => tech.id === id )

    return tecnico[0] || ''
  }

  filterVehicle(placa:string): VehicleModel  | ''  {
    let vehicle = this.vehiclesList
    .filter( car => car.placa === placa )

    if(vehicle[0]) {
      this.serviceInformations.controls['vehicleModel'].setValue(String(vehicle[0].modelo))
    }
    return vehicle[0] || ''
  }

  filterChip(id:string): ChipModel  | ''  {
    let chip = this.chipList
    .filter( c => Number(c.id) === Number(id) )

    return chip[0] || ''
  }

  filterEquipment(id:string): EquipmentModel  | ''  {
    let equipment = this.equipmentList
    .filter( equip => Number(equip.imei) === Number(id) )

    if(equipment[0]) {
      this.serviceInformations.controls['equipment'].setValue(String(equipment[0].modelo))
    }
    return equipment[0] || ''
  }


  filterCustomer(customerId:number): CustomerModel  | ''  {
    let customer = this.customerList
    .filter( cus => Number(cus.id) === customerId )

    return customer[0] || ''
  }

  serviceTypeFilter(type:string): string {
    let typeId:string = ''

    switch (type) {
      case 'Instalação' :
        typeId = '0'
        break
      case 'Manutenção' :
        typeId =  '1'
        break
      case 'Retirada' :
        typeId =  '2'
        break
    }
    return typeId
  }

  setDatetimeLocal(datetime: string) {
    const dt = new Date(datetime);
    return dt.toISOString().slice(0, 16);
  }

  editFormsBuilder(os: ServiceOrder) {

    this.value = Number(os.valor)

    this.createServiceOrder.controls['title'].setValue(os.nome)
    this.createServiceOrder.controls['deadline'].setValue(new Date(os.prazo_instalacao))

    this.serviceInformations.controls['serviceType'].setValue(this.serviceTypeFilter(os.serviceType))
    this.serviceInformations.controls['obs'].setValue(os.descricao)

    this.serviceInformations.controls['vehicleModel'].setValue(os.veiculo.modelo)

    this.dateTimeScheduled = this.setDatetimeLocal(os.data_agendamento)
    //this.customerForm.controls['dateTime'].setValue(new Date(os.data_agendamento))
    this.customerForm.controls['address'].setValue(os.endereco)

    this.customerForm.controls['addressNumber'].setValue(os.endereco_num)
    this.customerForm.controls['neighbourAddress'].setValue(os.bairro)
    this.customerForm.controls['city'].setValue(os.cidade)
    this.customerForm.controls['state'].setValue(os.estado)
    this.customerForm.controls['cep'].setValue(os.cep)

  }

  ngOnInit(): void {

    this.fetchData()
    this.OrderToEdit && this.editFormsBuilder(this.OrderToEdit)

  }

  fetchData() {
    this.getCustomers()
    this.getTechnicianList()
    this.getVehiclesList()
    this.getEquipment()
    this.getChip()
  }

  getCustomers(): void {
      this._customerService.getAllCustomersNoPaginate().subscribe((data) => {
        this.customerList = data
        this.filteredCustomerOptions = data

        if(this.isEdit) {
          this.customerSelected = this.filterCustomer(this.data.cliente_id)
        }
      })
  }

  getVehiclesList() {
    this._vehicleService.getFullListVehicles().subscribe((data) => {
      this.vehiclesList = data
      this.filteredVehicleOptions = data

      if(this.isEdit) {

        this.vehicleSelected =this.filterVehicle(this.data.veiculo.placa)
      }
    })
  }

  getTechnicianList() {
    this._technicianService.getFullTechniciansList().subscribe((data) => {
      this.technicianList = data
      this.filteredTechnicianOptions = data

      if(this.data) this.techSelected = this.filterTechnician(this.data.instalador_id)
    })
  }

  getEquipment() {
    this._equipmentService.getFullListEquipment().subscribe((data) => {
      this.equipmentList = data
      this.filteredEquipmentOptions = data

      if(this.isEdit) {
        this.equipmentSelected = this.filterEquipment(this.data.equipamento_id)
      }
    })
  }

  getChip() {
    this._chipService.getFullListChip().subscribe((data) => {
      this.chipList = data
      this.filteredChipOptions = data

      if(this.isEdit) {
        this.chipSelected = this.filterChip(this.data.chip.id)
      }
    })
  }

  onVehicleSelected(){

    const vehicleSelected: VehicleModel = this.serviceInformations.controls['licensePlate'].value

    this.serviceInformations.controls['vehicleModel']
    .setValue(vehicleSelected.modelo)

  }

  onCustomerSelected(event: any) {
    const customer =  event.option.value
    const enderecoNumero: number = customer.endereco_num
    const form = this.customerForm

    form.controls['cep'].setValue(customer.cep)
    this.handleSearchCep()

    enderecoNumero &&
    form.controls['addressNumber'].setValue(enderecoNumero)
  }

  handleSearchCep() {
    this.cepService.searchByCep(this.customerForm)
  }

  displayCustomer(data: any): string {
    return data? data.nome: ''
  }

  handleCloseModal() {
    this.dialogRef.close();
  }

  serviceOrderBuilder(): any {

    const customer = this.customerForm.controls['customerName'].value
    const technician = this.technicianForm.controls['technician'].value
    const equipment = this.serviceInformations.controls['equipment'].value
    const vehicle = this.serviceInformations.controls['licensePlate'].value
    const chip = this.serviceInformations.controls['chip'].value

    let order = {
      //createForm
      nome:this.createServiceOrder.controls['title'].value,
      prazo_instalacao: this.createServiceOrder.controls['deadline'].value,

      //technicianForm
      valor:this.technicianForm.controls['price'].value,
      instalador_id: technician.id,

      //serviceForm
      tipo_servico: this.serviceInformations.controls['serviceType'].value,
      descricao: this.serviceInformations.controls['obs'].value,

      veiculo_id: vehicle.id,
      equipment_id: equipment.imei ,
      chip_id: chip.id,

      //customerForm
      cliente_id: customer.id,
      data_agendamento: this.customerForm.controls['dateTime'].value,
      cep: this.customerForm.controls['cep'].value,
      endereco: this.customerForm.controls['address'].value,
      endereco_num: this.customerForm.controls['addressNumber'].value,
      bairro: this.customerForm.controls['neighbourAddress'].value,
      cidade: this.customerForm.controls['city'].value,
      estado: this.customerForm.controls['state'].value,
    }


    if(this.isEdit){
      let editOrder = {
        id: this.OrderToEdit!.id,
        ...order
      }

      order = editOrder
    }

    return order
  }




  ngAfterViewInit() {
    this.trigger.panelClosingActions.subscribe((e) => {

      if (!(e && e.source)) {
        this.filteredCustomerOptions = this.customerList
        this.customerForm.controls['customerName'].setValue('');
        this.trigger.closePanel();
      }
    });
  }


  handleSaveBtn(){
    this.ErrorStep = ''

    if(this.createServiceOrder.valid &&
      this.technicianForm.valid &&
      this.serviceInformations.valid &&
      this.customerForm.valid){

      const newOrder = this.serviceOrderBuilder()

      const req = !this.isEdit ?
      this._serviceOrderService.createOrder(newOrder):
      this._serviceOrderService.editOrder(newOrder)


      req.pipe(
        catchError((err: any) => {
          throw 'Erro: ' + err
        }),

      )
      .subscribe(
        {
          next: () =>  {
            this._serviceOrderService.clearCacheServ()
            this.dialog.closeAll()
            this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: !this.isEdit ? 'Ordem de serviço criada!': 'Ordem de serviço alterada!'
              })
          },
          error: (errorData:any) =>  {
            this._serviceOrderService.clearCacheServ()
            this.dialog.closeAll()
            this._bottomSheet.open(ErrorBottomSheet,
              { data:
                {
                  redirectTo: 'ordem-servico',
                  error: errorData
                }
              })
          }
        }
      )
    }else{

      if(!this.createServiceOrder.valid) {
        this.createServiceOrder.markAllAsTouched()
        this.ErrorStep  =  '1: OS'

      } else if(!this.technicianForm.valid) {
        this.technicianForm.markAllAsTouched()
        this.ErrorStep  =  '2: Cliente'

      } else if(!this.serviceInformations.valid) {
        this.serviceInformations.markAllAsTouched()
        this.ErrorStep  =  '3: Serviço'

      } else if(!this.customerForm.valid) {
        this.customerForm.markAllAsTouched()
        this.ErrorStep  =  '4: Técnico'
      }
    }

  }

}
