import { Component, Inject, Input, OnChanges, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { ServiceOrderService } from '../../service-order.service';
import { catchError } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SucessBottomSheetComponent } from 'src/app/features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import { ErrorBottomSheet } from 'src/app/features/helpers';
import {
  faClipboardCheck,
  faSpinner,
  faTimesCircle,
  faHourglass
} from '@fortawesome/free-solid-svg-icons'

  type inputFileModel = {
    id: number,
    src: any,
    image: File | null
    name?: string
  }

  type ServerFile = {
    url:string
    image_name: string
  }

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent extends BaseComponentComponent implements OnInit, OnChanges {

  faCanceledOrder = faTimesCircle
  faAwaitingOrder = faHourglass
  faInProgressOrder = faSpinner
  faFinishOrder = faClipboardCheck

  hasError: boolean = false;
  hasNewFiles: boolean = false

  @Optional() 
  @Input() 
  serviceOrder:ServiceOrder
  
  @Input()
  externAccessToken: string | null = null

  private osServerFiles: ServerFile[]

  public inputList:inputFileModel[] = [
    {
      id:1,
      src: '',
      image:null
    }
  ]

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ServiceOrder,
    private _osService: ServiceOrderService,
    private _serviceOrderService: ServiceOrderService,
    private _bottomSheet: MatBottomSheet,
    @Optional() private dialogRef:  MatDialogRef<any>,
    private _dialog: MatDialog,
  ) {
    super()
    if(data) this.serviceOrder = data
  }

  ngOnInit(): void {
    if(this.serviceOrder) {
      this.fetchImages()
    }
  }

  ngOnChanges() : void {

    this.fetchImages()
  }

  reloadServiceOrder() {
    this._osService.getServiceOrderById(this.serviceOrder.id).subscribe((data)=> {
      this.serviceOrder = data
    })
  }

  fetchImages():void {
    this._osService.getOsFiles(this.serviceOrder.id, this.externAccessToken).subscribe((res: any) => {
      this.osServerFiles = res

        if (res.length > 0) {
          this.inputList[0].src = res[0].url
          for (let i = 1; i < res.length; i++) {

            let input: inputFileModel = {
              id: this.inputList.length+1,
              src: res[i].url,
              image:null
            }
            this.inputList.push(input)

          }
        }

      }
      
    )
  }


  handleCloseDialog() {
    this._dialog.closeAll()
  }

  getClassStatusServiceOrder() {
    return {
      'em-espera': this.serviceOrder.status === 1,
      'em-progresso': this.serviceOrder.status === 2,
      'finalizada': this.serviceOrder.status === 3,
      'cancelada': this.serviceOrder.status === 4
    }
  }

  readURL(event: any,index:number): void {
    index -=  1

    this.inputList[index].image = event.target.files[0]

    let mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.inputList[index].src = reader.result;
    }

  this.checkDisableButton()
  }

  createNewInput():void {

    let newArrayList = this.inputList

    let input: inputFileModel = {
      id: this.inputList.length+1,
      src:'',
      image:null
    }

    newArrayList.push(input)
    this.inputList = newArrayList
  }

  removeInput(Input: inputFileModel):void {

    let index = this.inputList.indexOf(Input)

    const conditionToRemove = this.inputList[index].src && !this.inputList[index].image

    if(conditionToRemove) {
      const item: ServerFile[] = this.osServerFiles.filter(
        (item: ServerFile) => item.url === Input.src)

      this._osService.deleteOsFile(item[0].image_name,this.serviceOrder.id).subscribe((res: any) => {})
    }
    this.inputList.splice(index, 1);
    this.checkDisableButton()
  }

  findInputIndex(item:inputFileModel): number {
    return this.inputList.findIndex(input => input.id === item.id)
  }

  hasNull(element:inputFileModel, index:number) {
    return element.id===null || element.src===null 
  }

  validateInput(): boolean {
    return !this.inputList.some(this.hasNull)
  }

  renderFormatDateTime(dateTime: string): {date:string, time:string} {

    let d = new Date(dateTime)

    let dateFormatted = [
      d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate(),
      (d.getUTCMonth() + 1) < 10 ? '0' + (d.getUTCMonth() + 1) : (d.getUTCMonth() + 1),
      d.getUTCFullYear()
    ].join('/');
  
    let hour = d.getUTCHours();
    let minutes = d.getUTCMinutes();
    let timeFormatted = [
      hour < 10 ? '0' + hour : hour,
      minutes < 10 ? '0' + minutes : minutes
    ].join(':');

    return {
      date: dateFormatted,
      time: timeFormatted
    }
  }

  showErrorMessage(){
    this.hasError = true
    setTimeout(()=>{
      this.hasError = false
    },3500)
  }

  formDataBuilder(files:inputFileModel[]): FormData {
    let filesData: any = new FormData();

    for (let property in files) {
      const image = files[property].image

      filesData.append(
        files[property].id, image
      )
    }

    return filesData
  }

  filterblobFiles(){
    return this.inputList.filter(item => item.image !== null) 
  }

  disableInputValidation(item: inputFileModel):boolean {
    let result: boolean = false

    if( !item.src && item.image == null) result = false
    if( item.src && item.image == null) result = true

    return result
  }

  disableTextInputValidation(item: inputFileModel):boolean {
    let result: boolean = false

    if( !item.src && item.image == null) result = true

    return result
  }

  handleStatusChange(status: number) {

    let id = this.serviceOrder.id

    const requestParams = {
      osID: id, newStatus: status
    }
    
    this.setNewStatus(requestParams)
  }

  checkDisableButton():void {
    this.hasNewFiles = !!this.filterblobFiles()[0]
  }

  handleSendBtn() {
    if( this.validateInput() ) {
      const blobFiles = this.filterblobFiles()

      const filesData = this.formDataBuilder(blobFiles)
      this._osService.newcheckList(filesData, this.serviceOrder.id, this.externAccessToken)
      .pipe(
        catchError((err: any) => {
          throw err
        }),
      )
      .subscribe(
        {
          next: () =>  {
            
            const bottomSheetRef = this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: 'Checklist salvo!',
              })

              bottomSheetRef.afterDismissed().subscribe(data => {

                this._osService.clearCacheServ()

              })
              this.dialogRef.close();
          },
          error: (errorData) =>  {
            this._bottomSheet.open(ErrorBottomSheet,
              { data:
                {
                  redirectTo: 'ordem-servico',
                  error: errorData
                }
              })
          }
        }
      )

      }else {
        this.showErrorMessage()
      }
  }



  setNewStatus(
    {osID, newStatus}: {osID: number, newStatus: number}
    ){
    this._serviceOrderService.changeStatus(osID, newStatus)
    .pipe(
      catchError((err: any) => {
        throw 'Erro: ' + err
      }),

    )
    .subscribe(
      {
        next: () =>  {
          if(!this.externAccessToken){
            this._serviceOrderService.clearCacheServ()
          }else{
            this.reloadServiceOrder()
          }
          this._bottomSheet.open(SucessBottomSheetComponent,
            {
              data: 'Status alterado!'
            })
        },  
        error: (errorData:any) =>  {
          if(!this.externAccessToken)this._serviceOrderService.clearCacheServ()
          this._bottomSheet.open(ErrorBottomSheet, 
            { data: 
              {
                redirectTo: 'ordem-servico',
                error: errorData
              } 
            })
        }
      }
    )
  }

}
