import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new Subject<boolean>();
  constructor(private readonly ngxSpinner: NgxSpinnerService) {}

  show(): void {
    this.ngxSpinner
      .show()
      .then(() => {
        this.isLoading.next(true);
      })
      .catch((err) => {
        if (err instanceof Error) {
          throw new Error(err.message);
        }
      });
  }

  hide(): void {
    this.ngxSpinner
      .hide()
      .then(() => {
        this.isLoading.next(false);
      })
      .catch((err) => {
        if (err instanceof Error) {
          throw new Error(err.message);
        }
      });
  }
}
