import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ClientChargesComponent } from './client-charges/client-charges.component'
import { HomeComponent } from './home/home.component'


const routes: Routes = [
  {
    path: 'charges/:id/:hash',
    component: ClientChargesComponent
  },
  {
    path: ':id/:base',
    component: HomeComponent
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientAreaRoutingModule {}