<div class="card">
  <div class="card-header">
    <span
      class="number-control-service-order"
      [ngClass]="getClassStatusServiceOrder()"
      >{{ serviceOrderCard.id }}</span
    >
    <span
      class="title-service-order"
      [ngClass]="getClassStatusServiceOrder()"
      placement="top"
      ngbTooltip="{{serviceOrderCard.nome}}"
      >{{ serviceOrderCard.nome }}</span
    >

    <div ngbDropdown class="dropdown-card-service-order"
    userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
    userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_DELETE}}" 

    >
      <fa-icon
        size="lg"
        type="button"
        [icon]="faEllipsis"
        id="acoes-service-order"
        ngbDropdownToggle
      ></fa-icon>
      <div
        ngbDropdownMenu
        aria-labelledby="acoes-service-order"
        class="custom-dropdown-menu"
      >
        <div
          userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
          ngbDropdownItem
          (click)="handleEditServiceOrder(serviceOrderCard)"
        >
          <fa-icon [icon]="faEdit"></fa-icon>Editar
        </div>

        <div
          userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_DELETE}}" 
          class="d-flex flex-row"
          ngbDropdownItem
          (click)="handleDeleteServiceOrder(serviceOrderCard.id)"
        >
          <fa-icon [icon]="faDelete"></fa-icon>Deletar
        </div>

        <div
        userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_DELETE}}" 
        class="d-flex flex-row"
        ngbDropdownItem
        (click)="handleShareLink(serviceOrderCard.id)"
      >
        <fa-icon [icon]="shareBtn"></fa-icon>Compartilhar
      </div>

      </div>
    </div>
  </div>

  <app-info-card-service-order
    [infoServiceOrder]="serviceOrderCard"
    (click)="openCheklistModal(serviceOrderCard)"
  ></app-info-card-service-order>

  <div class="card-footer" [ngClass]="getClassStatusServiceOrder()">
    <div class="text">
      <fa-icon [icon]="getIconForServiceOrder()"></fa-icon
        >{{ getTextStatusCard() }}
    </div>

    <div ngbDropdown #statusDrop="ngbDropdown" class="statusDrop mr-n3">
      <div
        userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_DELETE}}" 
        class="d-flex flex-row pl-4"
        size="md"
        type="button"
        ngbDropdownToggle>
        <fa-icon [icon]="faSettings"></fa-icon>
        <div ngbDropdownMenu
        class="dropdown-status">
          <div 
            class="drop-down-item"
            (click)="handleStatusChange(1)"
            [ngClass]="{'active-status':this.serviceOrderCard.status === 1}"
            userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
            ngbDropdownItem>
            <fa-icon [icon]="this.faAwaitingOrder"></fa-icon>Em Espera
          </div>
          <div 
            class="drop-down-item"
            (click)="handleStatusChange(2)"
            [ngClass]="{'active-status':this.serviceOrderCard.status === 2}"
            userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
            ngbDropdownItem>
            <fa-icon [icon]="this.faInProgressOrder"></fa-icon>Em progresso
          </div>
          <div 
            (click)="handleStatusChange(3)"
            class="drop-down-item"
            [ngClass]="{'active-status':this.serviceOrderCard.status === 3}"
            userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
            ngbDropdownItem>
            <fa-icon [icon]="this.faFinishOrder"></fa-icon>Finalizada
          </div>
          <div 
          class="drop-down-item"
          (click)="handleStatusChange(4)"
          [ngClass]="{'active-status':this.serviceOrderCard.status === 4}"
          userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_UPDATE}}" 
          ngbDropdownItem>
            <fa-icon [icon]="this.faCanceledOrder"></fa-icon>Cancelada
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
