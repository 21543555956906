import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  darkTheme = new EventEmitter<boolean>()

  // constructor() {}

  toggleDarkTheme(darkThemeFlag: boolean) {
    this.darkTheme.emit(darkThemeFlag)
  }
}
