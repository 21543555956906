import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable, shareReplay } from 'rxjs'
import { EventEmitter } from '@angular/core'
import { EquipmentModel, EquipmentParams, EquipmentRequest } from './equipment'
import { TelefonePipe } from 'src/app/shared/pipes/telefone.pipe'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  constructor(
    private _httpClient: HttpClient,
    private _phonePipe: TelefonePipe
  ) {}

  private url = environment.apiUrl + '/equipamentos'
  private equipmentList$: Observable<EquipmentModel[]> | null = null
  public total_records = 0
  public equipmentToEdit: EquipmentModel = {} as EquipmentModel
  public equipmentToDelete: string = ''
  public equipmentListEventEmitter: EventEmitter<EquipmentModel[]> =
    new EventEmitter()

  private formatEquipment(list: EquipmentModel[]): EquipmentModel[] {
    const newListEquipment: EquipmentModel[] = []

    list.map((element) => {
      const newEquipment: EquipmentModel = {
        ...element,
      }
      newListEquipment.push(newEquipment)
    })

    return newListEquipment
  }

  public getAllEquipments(objParams?:EquipmentParams) {

    let request:any
    if(objParams) {

      const params = new HttpParams()
      .set('page', objParams.page)
      .set('page_size', objParams.page_size)
      .set('term', objParams.term)
      .set('tags', objParams.filter?.join(","));

      request = this._httpClient.get<EquipmentRequest>(this.url, {params})

    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      request = this._httpClient.get<EquipmentRequest>(this.url, {params})
    }
    
    request.subscribe((res:EquipmentRequest) => {
      this.total_records = res.total_records }
    )

    this.equipmentList$ = request.pipe(
      map((data:EquipmentRequest) => this.formatEquipment(data.results)),
      shareReplay(1)
    )
    

    this.equipmentList$!.subscribe((data) => {
      this.equipmentListEventEmitter.emit(data)
    })
  }

  public clearEquipmentCache() {
    this.equipmentList$ = null
    this.getAllEquipments()
  }

  saveNewEquipment(equipment: Omit<EquipmentModel, 'status' | 'nome_loja'>) {
    return this._httpClient.post<EquipmentModel>(this.url, equipment)
  }

  getEquipmentTypes():Observable<any>  {
    return this._httpClient.get(this.url+'/types')
  }

  editEquipment(equipment: EquipmentModel, imei:number) {
    return this._httpClient.put<EquipmentModel>(this.url+'/'+imei, equipment)
  }

  deleteEquipment() {
    return this._httpClient.delete<any>(
      this.url+'/'+this.equipmentToDelete
    )
  }

  public getFullListEquipment():  Observable<any> {

    return this._httpClient
    .get<EquipmentRequest>(this.url+'/lista')
  
  }

  public getInfoOsEquipmentById(id: string): Observable<ServiceOrder[]> {
    return this._httpClient.get<ServiceOrder[]>(`${this.url}/orders-service/${id}`);
  };
}
