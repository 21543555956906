import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilwebFilterComponent } from './agilweb-filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [ AgilwebFilterComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ], exports: [ AgilwebFilterComponent ]
})
export class AgilwebFilterModule { }
