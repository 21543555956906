import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import { AdminAuthorized } from './core/guards/admin-authorized.guard'

import {UserAlreadyLogged} from './core/guards/user-already-logged.guard'
import {UserAuthorized} from './core/guards/user-authorized.guard'


const routes: Routes = [
    {
        path: 'auth',
        loadChildren: async () =>
            await import('./features/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [UserAlreadyLogged],
        data: {
            headerConfig :  {
                headerTitle: 'Dashboard',
                ctaHeaderLabel: 'Relatórios'
            }
        }

    },
    {
        path: 'dashboard',

        loadChildren: async () =>
            await import('./features/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
        canActivate: [UserAuthorized],
        data: {
            pageTitle: 'DashBoard'
        }

    },
    {
        path: 'customers',
        loadChildren: async () =>
            await import('./features/customer/customer.module').then(
                (m) => m.CustomerModule
            ),
        canActivate: [UserAuthorized]
    },

    { path: 'work-panel', loadChildren:
            () => import('./features/work-panel/work-panel.module').then(m => m.WorkPanelModule)
    },

    {
        path: 'devices',
        loadChildren: async () =>
            await import('./features/equipment/devices.module').then(
                (m) => m.DevicesModule
            ),
        canActivate: [UserAuthorized]
    },

    {
        path: 'vehicles',
        loadChildren: async () =>
            await import('./features/vehicle/vehicle.module').then((m) => m.VehicleModule),
        canActivate: [UserAuthorized]
    },
    {
        path: 'statement',
        loadChildren: async () =>
            await import('./features/statement/statement.module').then(
                (m) => m.StatementModule
            ),
        canActivate: [UserAuthorized]
    },

    {
        path: 'dunnings',
        loadChildren: async () =>
            await import('./features/dunnings/list-dunning.module').then(
                (m) => m.ListDunningModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'invoice',
        loadChildren: async () =>
            await import('./features/invoice/invoice.module').then(
                (m) => m.InvoiceModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'profile',
        loadChildren: async () =>
            await import('./features/user/user.module').then(
                (m) => m.UserModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'admin',
        loadChildren: async () =>
            await import('./features/admin/admin.module').then(
                (m) => m.AdminModule
            ),
        canActivate: [AdminAuthorized]
    },
    {
        path: 'charges',
        loadChildren: async () =>
            await import('./features/charges/charges.module').then(
                (m) => m.ChargesModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'area-cliente',
        loadChildren: async () =>
            await import('./features/client-area/client-area.module').then(
                (m) => m.ClientAreaModule
            ),
    },
    {
        path: 'ordem-servico',
        loadChildren: async () =>
            await import('./features/service-order/service-order.module').then(
                (m) => m.ServiceOrderModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'tecnicos',
        loadChildren: async () =>
            await import('./features/technician/technician.module').then(
                (m) => m.TechnicianModule
            ),
        canActivate: [UserAuthorized]
    },

    {
        path: 'relatorios',
        loadChildren: async () =>
            await import('./features/reports/reports.module').then(
                (m) => m.ReportsModule
            ),
        canActivate: [UserAuthorized]
    },
    {
        path: 'external',
        loadChildren: async () =>
            await import('./features/external-links/external-links.module').then(
                (m) => m.ExternalLinksModule
            )
    },
    {
        path: 'products',
        loadChildren: async () =>
            await import('./features/product/product.module').then(
                (m) => m.ProductModule
            )
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },

    {
        path: '**',
        redirectTo: ''
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
