import { HttpClient, HttpParams } from '@angular/common/http'
import { map, Observable, shareReplay } from 'rxjs'
import { EventEmitter, Injectable } from '@angular/core'

import {CustomerModel, CustomerRequest, CostumerParams} from './customer'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { environment } from 'src/environments/environment'
import { TelefonePipe } from 'src/app/shared/pipes/telefone.pipe'
import { PageParams } from './detail-customer/financeiro/financeiro.component'
import { VehicleRequest } from '../vehicle/vehicle'
import { TAcession } from './detail-customer/produto-customer/accession'



@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private customerList$: Observable<CustomerRequest> | null = null
  public customerListEventEmmiter: EventEmitter<CustomerRequest> =
  new EventEmitter()
  public CustomerToEdit: CustomerModel = {} as CustomerModel
  private accessionsList$: Observable<any> | null = null
  public accessionsListEventEmmiter: EventEmitter<any> =
    new EventEmitter()



  constructor(
    private httpClient: HttpClient,
    private telefonePipe: TelefonePipe
  ) {}



  public clearCacheCustomerList(): void {
    this.customerList$ = null
    this.getAllCustomers()
  }

  public getAllCustomers(objParams?:CostumerParams): void {
    let params = new HttpParams();

    if (objParams) {
      if (objParams.page) {
        params = params.set('page', objParams.page.toString());
      }
      if (objParams.page_size) {
        params = params.set('page_size', objParams.page_size.toString());
      }
      if (objParams.term) {
        params = params.set('term', objParams.term);
      }
      if (objParams.filter && objParams.filter.length > 0) {
        params = params.set('tags', objParams.filter.join(","));
      }
    } else {
      params = params.set('page', '1').set('page_size', '10');
    }

    this.customerList$ = this.httpClient
      .get<CustomerRequest>(environment.apiUrl + '/clientes', { params })
      .pipe(
        map((data) => this.formatCustomers(data)),
        shareReplay(1)
      );

    this.customerList$.subscribe((data) => {
      this.customerListEventEmmiter.emit(data)
    });
  }

  public getAllCustomersNoPaginate(): Observable<any> {
    return this.httpClient
      .get<any>(environment.apiUrl + '/clientes/lista')
  }


  public getCustomerCharges(id: any, PageParams?: PageParams): Observable<any> {

    let params = new HttpParams()
    .set('page', 1)
    .set('page_size', 10);
    
    if(PageParams) {
      params = new HttpParams()
      .set('page', PageParams.page)
      .set('page_size', PageParams.page_size);
    }

    return this.httpClient
        .get<any>(environment.apiUrl + '/cobrancas/'+id, {params});

  }

  public getCustomerSubscriptions(id: any): Observable<any> {
    return this.httpClient
        .get<any>(environment.apiUrl + '/assinaturas/'+id);

  }



  public getCustomer(id: any): Observable<any> {
    return this.httpClient
        .get<any>(environment.apiUrl + '/clientes/'+id);

  }

  private formatCustomers(request: CustomerRequest): CustomerRequest {
    const formattedCustomerList: CustomerModel[] = []
    request.results.map((element) => {
      if (element) {
        const newCustomer: CustomerModel = {
          ...element,
          cpf_cnpj:
            element.cpf_cnpj.length === 11
              ? cpf.format(element.cpf_cnpj)
              : cnpj.format(element.cpf_cnpj),
          whatsapp: element.whatsapp == null ? '' : this.telefonePipe.transform(element.whatsapp)
        }
        formattedCustomerList.push(newCustomer)
      }
    })

    request.results = formattedCustomerList
    return request;
  }


  public saveNewCharge( charge:any) {
    return this.httpClient.post<CustomerModel>(
        environment.apiUrl + '/cobrancas',
        charge
    )
  }

  public editCharge( charge:any) {
    return this.httpClient.put<CustomerModel>(
        environment.apiUrl + '/cobrancas',
        charge
    )
  }


  public saveNewSub( charge:any) {

    return this.httpClient.post<CustomerModel>(
        environment.apiUrl + '/assinaturas',
        charge
    )
  }

  public editSub( charge:any) {

    return this.httpClient.put<CustomerModel>(
        environment.apiUrl + '/assinaturas',
        charge
    )
  }

  public deleteCharge(id: string) {
    return this.httpClient.delete<CustomerModel>(
        environment.apiUrl + '/cobrancas/' + id
    )
  }


  public deleteSubscription(id: string) {
    return this.httpClient.delete<CustomerModel>(
        environment.apiUrl + '/assinaturas/' + id
    )
  }





  public saveNewCustomer(customer: CustomerModel) {
    return this.httpClient.post<CustomerModel>(
      environment.apiUrl + '/clientes',
      customer
    )
  }

  public editCustomer(customer: CustomerModel, idCustomer: string) {
    return this.httpClient.put<CustomerModel>(
      environment.apiUrl + '/clientes/' + idCustomer,
      customer
    )
  }

  public deleteCustomer(id: string) {
    return this.httpClient.delete<CustomerModel>(
      environment.apiUrl + '/clientes/' + id
    )
  }

  reactivateCustomer(customer_id:number) {
    return this.httpClient.patch<CustomerModel>(
      environment.apiUrl + '/clientes/' + customer_id, {}
    )
  }

  public sendBoletoUrl(charge: any, customer: string) {
    return this.httpClient.post<CustomerModel>(
        environment.apiUrl + '/clientes/message/' + customer,
        charge
    )
  }

  public sendEmailCharge(idCustomer: number, charge: any) {
    return this.httpClient.post<CustomerModel>(
      `${environment.apiUrl}/notificacao/send-email/${idCustomer}`,
        charge
    )
  }

  public exportCustomerList(objParams:CostumerParams): Observable<any> {
    console.log(objParams)

    const params = new HttpParams()
    .set('term', objParams.term)
    .set('tags', objParams.filter?.join(","));

    return this.httpClient.get<any>(environment.apiUrl + '/clientes/export', {params})
  }

  public getAccount(): Observable<any> {

    return this.httpClient
        .get<any>(`${environment.apiUrl}/user/integration`);
  }

  public membershipProduct(membershipData: any, customerId: number): Observable<any> {   
    return this.httpClient
        .post<any>(`${environment.apiUrl}/produtos/accession/${customerId}`, membershipData);
  }

  public deleteAccession(idAccession: number): Observable<any> {
    return this.httpClient
      .delete(`${environment.apiUrl}/produtos/accession/unique/${idAccession}`)
  }

  public getAccession(idAccession: number): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/produtos/accession/unique/${idAccession}`)
  }

  public getProducts(): Observable<any> {
    return this.httpClient
        .get<any>(`${environment.apiUrl}/produtos/all`);
  }

  public getFullListVehicles(): Observable<any> {
    return this.httpClient
      .get<VehicleRequest[]>(`${environment.apiUrl}/veiculos/lista`,)
  }

  public getFullVehiclesAccession(idAccession: number): Observable<any> {
    return this.httpClient
      .get<VehicleRequest[]>(`${environment.apiUrl}/produtos/accession/vehicle/${idAccession}?paged=false`,)
  }

  public getFullListAccessions(idClient: number): void {
    this.accessionsList$ = this.httpClient
    .get<TAcession[]>(`${environment.apiUrl}/produtos/accession/${idClient}`)

    this.accessionsList$?.subscribe((data) => {
      this.accessionsListEventEmmiter.emit(data)
    })
  }

  public clearCacheAccessionsList(idClient: number) {
    this.accessionsList$ = null
    this.getFullListAccessions(idClient)
  }

  public editAccession(idAccession: number, newData: any): Observable<Response> {
    return this.httpClient
    .put<any>(`${environment.apiUrl}/produtos/accession/unique/${idAccession}`, newData)
  }

  public accessionActivateDeactivate(idAccession: number, action: string, body: { nextDueDate: string }): Observable<Response> {
    return this.httpClient
    .put<any>(`${environment.apiUrl}/produtos/accession/${idAccession}/toggle-activation/${action}`, body)
  }
}
