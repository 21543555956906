import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Observable, shareReplay, map } from 'rxjs'
import { environment } from '../../../environments/environment'
import { TechnicianModel, TechnicianRequest } from './technician'
import { cpf } from 'cpf-cnpj-validator'
import { TelefonePipe } from 'src/app/shared/pipes/telefone.pipe'
import { ParamsConfig } from '../admin/account'

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  constructor(
    private _httpClient: HttpClient,
    private _phonePipe: TelefonePipe
  ) {}
  private url = environment.apiUrl + '/instaladores'

  private technicianList$: Observable<TechnicianRequest> | null = null

  public technicianToEdit: TechnicianModel = {} as TechnicianModel
  public technicianToDelete: string = ''

  public totalTechnicians: number = 0
  public paginationData: any = {}

  public technicianListEventEmitter: EventEmitter<TechnicianRequest> =
    new EventEmitter()

  public clearTechnicianCache() {
    this.technicianList$ = null
    this.getAllTechnicians()
  }

  public getAllTechnicians(_params?: ParamsConfig) {

    const params = {
      page: _params ? _params.page + 1 : 1,
      page_size: 20,
      search: _params ? _params.term : ''
    }
    
    const req = this._httpClient
      .get<TechnicianRequest>(this.url, {
        params
      })

      req.subscribe((data)=>{
        this.paginationData.current_page = data.current_page
        this.paginationData.total_records = data.total_records
        this.paginationData.page_size = data.page_size
      })

      this.technicianList$ = req.pipe(
        map((data) => this.formatTechnicians(data)),
        shareReplay(1)
      )

    this.technicianList$.subscribe((data) => {
      this.technicianListEventEmitter.emit(data)
    })
  }



  private formatTechnicians(request: TechnicianRequest): TechnicianRequest {
    const newListTechnicians: TechnicianModel[] = []

    request.results.map((element: TechnicianModel) => {
      const newTechnician: TechnicianModel = {
        ...element,
        cpf: cpf.format(element.cpf),
        whatsapp: this._phonePipe.transform(element.whatsapp),
        telefone: element.telefone
          ? this._phonePipe.transform(element.telefone)
          : undefined
      }
      newListTechnicians.push(newTechnician)
    })
    request.results = newListTechnicians

    return request
  }

  public saveNewTechnician(
    technician: TechnicianModel
  ): Observable<TechnicianModel> {
    return this._httpClient.post<TechnicianModel>(this.url, technician)
  }

  public updateTechnician(
    id: string,
    technician: TechnicianModel
  ): Observable<TechnicianModel> {
    return this._httpClient.put<TechnicianModel>(
      `${this.url}/${id}`,
      technician
    )
  }

  public deleteTechnician(id: string): Observable<any> {
    return this._httpClient.delete(`${this.url}/${id}`)
  }

  getFullTechniciansList(): Observable<any> {
    return this._httpClient
    .get<TechnicianRequest>(this.url+'/lista')    
  }
}
