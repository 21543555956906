

<div class="card-body" id="info-card-os">
  
  <div class="d-flex flex-row">
    <fa-icon
      [ngClass]="getClassStatusServiceOrder()"
      [icon]="faClient"
    ></fa-icon>
    <div class="d-flex flex-column">
      <span class="title-order">Cliente</span>
      <span class="info-order">{{ infoServiceOrder.customer }}</span>
    </div>
  </div>

  <div class="d-flex flex-row">
    <fa-icon
      [ngClass]="getClassStatusServiceOrder()"
      [icon]="faServiceType"
    ></fa-icon>
    <div class="d-flex flex-column">
      <span class="title-order">Tipo de serviço</span>
      <span class="info-order">{{ infoServiceOrder.serviceType }}</span>
    </div>
  </div>
    <div class="d-flex flex-row">
    <fa-icon
      [ngClass]="getClassStatusServiceOrder()"
      [icon]="faSatellitedish"
    ></fa-icon>
    <div class="d-flex flex-column">
      <span class="title-order">Equipamento</span>
      <span class="info-order"> {{ infoServiceOrder.equipamento_modelo }}</span>
      <span class="info-order"> {{ infoServiceOrder.equipamento_id }}</span>
    </div>
  </div>

  <div class="d-flex flex-row">
    <fa-icon
      [ngClass]="getClassStatusServiceOrder()"
      [icon]="faExecutionDate"
    ></fa-icon>
    <div class="d-flex flex-column">
      <span class="title-order">Data</span>
      <span class="info-order">{{
        renderFormatDateTime(infoServiceOrder.data_agendamento).date
      }}</span>
      <span class="info-order">{{
        renderFormatDateTime(infoServiceOrder.data_agendamento).time
      }}</span>
    </div>
  </div>



  <!-- <div class="d-flex flex-row">
    <fa-icon
      [ngClass]="getClassStatusServiceOrder()"
      [icon]="faAttachment"
    ></fa-icon>
    <div class="d-flex flex-column justify-content-center">
      <span class="title-order">{{ infoServiceOrder.attachment }}</span>
    </div>
  </div> -->
</div>
