import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {ToolBarConfig} from "./toolbar/toolbar";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {BaseComponentComponent} from "../base-component/base-component.component";
import { AuthService } from 'src/app/features/auth/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends BaseComponentComponent implements OnInit {
  faSearch = faSearch


  public headerConfig! : ToolBarConfig;

  @ViewChild('sidebar')
  public sideBar!: SidebarComponent

  constructor(public authService: AuthService) {
    super();

  }


  ngOnInit(): void {


  }
}
