import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http'
import { finalize, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { LoaderService } from 'src/app/shared/loader/loader.service'

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show()
    return next.handle(req).pipe(finalize(() => this.loaderService.hide()))
  }
}
