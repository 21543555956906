import {Component, Inject} from "@angular/core";
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import { ErrorObj } from "../../helpers/index";


@Component({
    selector: 'error-bottom-sheet',
    templateUrl: 'error-bottom-sheet.html',
    styleUrls: ['./error-bottom-sheet.component.scss']
})
export class ErrorBottomSheet {

    errorMessage : any = 'Erro'

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: ErrorObj,
        private _bottomSheetRef: MatBottomSheetRef<ErrorBottomSheet>,
        private readonly router: Router,
        public dialog: MatDialog
    ) {

        this.errorMessage = this.getErrorMessage(data.error)

    }

    getErrorMessage( data: any ): void {

        let errorObj = data.error

        if(errorObj?.message){
            return errorObj.message

        }else if (errorObj?.error){
            return errorObj.error

        } else {
            return data.message
        }

    }

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
        event.preventDefault();
    }

    next (): void{


        this._bottomSheetRef.dismiss();


    }

    close (): void {

        this._bottomSheetRef.dismiss();

    }

}
