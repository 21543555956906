<header id="header" [ngClass]="getClassStatusServiceOrder()">
    <h1>Detalhes OS-{{serviceOrder.id}}</h1>

    <div class="close-icon-container" (click)="handleCloseDialog()">
        <mat-icon 
            *ngIf="!externAccessToken"
            class="close-icon" 
            inline=true 
            svgIcon="close-tab-icon" 
            mat-list-icon>
        </mat-icon>
    </div>
</header>

<main class="main-container" [ngClass]="getClassStatusServiceOrder()">
    <div [ngClass]="{'mobile-info-container': isPhone, 'info-container': !isPhone}">
        <div class="info">
            <span class="title">Cliente:</span>
            <span class="description"> {{serviceOrder.customer}} </span>
        </div>

        <div class="info">
            <span class="title">Data:</span>
            <span class="description"> 
                {{renderFormatDateTime(serviceOrder.data_agendamento).date}} 
            </span>
        </div>
        <div class="info">
            <span class="title">Horário:</span>
            <span class="description"> 
                {{renderFormatDateTime(serviceOrder.data_agendamento).time}}  
            </span>
        </div>

        <div class="info">
            <span class="title">Tipo:</span>
            <span class="description"> {{serviceOrder.serviceType}} </span>
        </div>
        <div class="info">
            <span class="title">Equipamento:</span>
            <span class="description"> 
                {{serviceOrder.equipamento_modelo}} 
            </span>
            <span class="description"> 
                {{serviceOrder.equipamento_id}} 
            </span>
        </div>

        <div class="info">
            <span class="title">Veículo:</span>
            <span class="description"> 
                {{serviceOrder.veiculo.marca}} {{serviceOrder.veiculo.modelo}} - {{serviceOrder.veiculo.placa}} 
            </span>
        </div>


        <div class="info">
            <span class="title">Endereço:</span>
            <span class="description"> {{serviceOrder.endereco}}, {{serviceOrder.endereco_num}} </span>
        </div>

        <div class="info">
            <span class="title">Bairro:</span>
            <span class="description"> 
                {{serviceOrder.bairro}} 
            </span>
        </div>
        <div class="info">
            <span class="title">Cidade/Estado:</span>
            <span class="description"> 
                {{serviceOrder.cidade}} , {{serviceOrder.estado}}
            </span>
        </div>
        <div class="info">
            <span class="title">CEP:</span>
            <span class="description"> 
                {{serviceOrder.cep}}
            </span>
        </div>
        
    </div>
    <div *ngIf="serviceOrder.descricao" class="obs-container">
        <span class="title">Observação :</span>
        <span class="description"> 
            {{serviceOrder.descricao}}
        </span> 
    </div>

    <div id="check-list-section">
        <header id="checklist-header">Checklist</header>
        <div class="inputs-container"
        [ngClass]="{'mobile-info-container': isPhone}">
            <div
                *ngFor="let item of inputList"
                class="input-box"
                [ngClass]="{'mobile-input-box': isPhone}">

                <span
                class="trash-icon"
                (click)="removeInput(item)">
                    <mat-icon
                    matTooltip="Deletar esta caixa"
                    matTooltipPosition="above" > delete </mat-icon>
                </span>

                <label for={{item.id}}>
                    <div class="img-container" *ngIf="item.src">
                        <img id="inputImagePreview"
                        [src]="item.src" alt="Imagem selecionada" />
                    </div>

                    <div class="upload-container" *ngIf="!item.src">
                        <span *ngIf="!item.src">
                            <mat-icon> add_a_photo </mat-icon>
                        </span>
                    </div>
                    <span class="upload-button" *ngIf="disableTextInputValidation(item)"> Upload </span>
                    <span class="upload-button" *ngIf="item.src && item.image"> Trocar imagem </span>

                </label>

                <input
                    [disabled]="disableInputValidation(item)"
                    id={{item.id}}
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="readURL($event, item.id)"
                >
            </div>

            <div
            (click)="createNewInput()"
            class="input-box add-new-input"
            [ngClass]="{'add-new-input-mobile': isPhone}">
                <mat-icon
                matTooltip="Adicionar nova caixa de upload"
                matTooltipPosition="above" > add </mat-icon>
            </div>

        </div>
        <p *ngIf="this.hasError" class="error">Selecione uma imagem para cada caixa ativa.</p>
        <footer>


            <div 
                ngbDropdown 
                placement="top-start"
                #statusDrop="ngbDropdown" 
                class="statusDrop mr-n3"
                *ngIf="externAccessToken"
                >
                <div
                    class="d-flex flex-row pl-4"
                    size="md"
                    type="button"
                    ngbDropdownToggle>
                    <button class="change-status"> Alterar status </button>
                    <div ngbDropdownMenu
                    class="dropdown-status">
                    <div 
                        class="drop-down-item"
                        [ngClass]="{'active-status':this.serviceOrder.status === 1}"
                        (click)="handleStatusChange(1)"
                        ngbDropdownItem>
                        <fa-icon [icon]="this.faAwaitingOrder"></fa-icon>Em Espera
                    </div>
                    <div 
                        class="drop-down-item"
                        (click)="handleStatusChange(2)"
                        [ngClass]="{'active-status':this.serviceOrder.status === 2}"
                        ngbDropdownItem>
                        <fa-icon [icon]="this.faInProgressOrder"></fa-icon>Em progresso
                    </div>
                    <div 
                        (click)="handleStatusChange(3)"
                        class="drop-down-item"
                        [ngClass]="{'active-status':this.serviceOrder.status === 3}"
                        ngbDropdownItem>
                        <fa-icon [icon]="this.faFinishOrder"></fa-icon>Finalizada
                    </div>
                    <div 
                    class="drop-down-item"
                    (click)="handleStatusChange(4)"
                    [ngClass]="{'active-status':this.serviceOrder.status === 4}"
                    ngbDropdownItem>
                        <fa-icon [icon]="this.faCanceledOrder"></fa-icon>Cancelada
                    </div>
                    </div>
                </div>
            </div>

            <button 
                disabled="{{!this.hasNewFiles}}"
                mat-raised-button color="primary" 
                (click)="handleSendBtn()"> Enviar </button>
        </footer>
    </div>
</main>
