import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core'
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {
  faBriefcase,
  faArrowRight,
  faCommentAlt,
  faColumns,
  faTools,
  faHardHat,
  faUsers,
  faStore,
  faSignOutAlt,
  faListUl
} from '@fortawesome/free-solid-svg-icons'

import { Router } from '@angular/router'
import { AuthService } from 'src/app/features/auth/auth.service'
import {MatDrawer} from "@angular/material/sidenav";
import {BaseComponentComponent} from "../../base-component/base-component.component";
import {AppResponsive} from "../../../app.module";

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.html',
  styleUrls: ['./sidebar-admin.scss']
})
export class AdminSidebarComponent extends BaseComponentComponent implements OnInit {
  faBriefcase = faBriefcase
  faArrowRight = faArrowRight
  faCommentAlt = faCommentAlt
  faColumns = faColumns
  faTools = faTools
  faUsers = faUsers
  faStore = faStore
  faHardHat = faHardHat
  faSignOut = faSignOutAlt
  faServiceOrder = faListUl



  constructor(
    private readonly router: Router,
    public authService: AuthService,
  ) {
    super()

  }

  @ViewChild('drawer')
  private matDrawer!: MatDrawer

  ngOnInit(): void {
    
    
  }
  

  handleRedirectTo(component: string) {
    this.router.navigateByUrl(component)
  }

  toggleAdminSideBar() {

    this.matDrawer.toggle();


  }

  goToHomePage(): void {

    this.router.navigateByUrl('/admin')
    
  }

  handleLogout() {
    this.authService.logout()
  }
}
