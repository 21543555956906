import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {
  transform(value: string): string {
    const valueUnformatted = value.replace(/\D/g, '')
    if (/([0-9])/.test(valueUnformatted)) {
      if (valueUnformatted.length === 11) {
        const newValue =
          '(' +
          valueUnformatted.substring(0, 2) +
          ') ' +
          valueUnformatted.substring(2, 7) +
          '-' +
          valueUnformatted.substring(7, 11)
        return newValue
      } else if (valueUnformatted.length === 10) {
        const newValue =
          '(' +
          valueUnformatted.substring(0, 2) +
          ') ' +
          valueUnformatted.substring(2, 6) +
          '-' +
          valueUnformatted.substring(6, 10)
        return newValue
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }
}
