import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  faEllipsisV,
  faTrashAlt,
  faPen,
  faCog,
  faShare,
  faLink
} from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert2'

import {
  faTimesCircle,
  faHourglass,
  faSpinner,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons'

import { getTextStatusServiceOrder } from '../../helpers'
import { ServiceOrderService } from '../../service-order.service'
import { catchError, Subject, takeUntil } from 'rxjs'
import { CreateServiceOrderComponent } from '../../create-service-order/create-service-order.component'
import { MatDialog } from '@angular/material/dialog'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { SucessBottomSheetComponent } from 'src/app/features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component'
import { ErrorBottomSheet } from 'src/app/features/helpers'
import { ACCESS_CONTROL_TYPES } from 'src/app/core/roles'
import { ChecklistComponent } from '../checklist/checklist.component'
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component'
import { ExternalService } from 'src/app/features/external-links/externalService.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-card-service-order',
  templateUrl: './card-service-order.component.html',
  styleUrls: ['./card-service-order.component.scss']
})
export class CardServiceOrderComponent extends BaseComponentComponent implements OnInit, OnDestroy {
  $unsubscribe: Subject<void> = new Subject()

  faCanceledOrder = faTimesCircle
  faAwaitingOrder = faHourglass
  faInProgressOrder = faSpinner
  faFinishOrder = faClipboardCheck
  faEdit = faPen
  faDelete = faTrashAlt
  faSettings = faCog
  shareBtn = faShare
  faEllipsis = faEllipsisV
  accessControl = ACCESS_CONTROL_TYPES

  UrlSharedOS: string =""

  @Input()
  serviceOrderCard: ServiceOrder = {} as ServiceOrder

  textStatusServiceOrder
  dropdownId = `dropdown-card-service-order-${this.serviceOrderCard.id}`

  constructor(
    private _serviceOrderService: ServiceOrderService,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private _externalService: ExternalService,
    private _snackBar: MatSnackBar,
    

  ) {
    super()
    this.textStatusServiceOrder = getTextStatusServiceOrder(
      this.serviceOrderCard.status
    )
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.$unsubscribe.next()
    this.$unsubscribe.complete()
  }

  handleEditServiceOrder(ordem: ServiceOrder) {

    const dialogRef = this._dialog.open(CreateServiceOrderComponent,
    {
      panelClass: 'rounded-dialog',
      data: ordem 
    }
    );

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  handleShareLink(OsId:number) {
    this._externalService.getJWTToken(OsId).subscribe((data)=>{

      this.UrlSharedOS = `${location.origin}/external/os/${data}`

      this.handleCopyText()
    })
  }

  handleCopyText() {
    navigator.clipboard.writeText(this.UrlSharedOS);
    
    this._snackBar.open('Link copiado para a área de transferência', 'OK', {
      duration: 2500,
      panelClass: 'agil-snackbar'
    });

  }

  handleDeleteServiceOrder(id: number) {
    this._serviceOrderService
      .deleteServiceOrder(id)
      .pipe(
        catchError((err: any) => {
          throw 'Erro: ' + err
        }),

      )
      .subscribe(
        {
          next: () =>  {
            this._serviceOrderService.clearCacheServ()
            this.dialog.closeAll()
            this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: 'Ordem de serviço deletada!'
              })
          },  
          error: (errorData:any) =>  {
            this._serviceOrderService.clearCacheServ()
            this.dialog.closeAll()
            this._bottomSheet.open(ErrorBottomSheet, 
              { data: 
                {
                  redirectTo: 'ordem-servico',
                  error: errorData
                } 
              })
          }
        }
      )
  }

  getIconForServiceOrder() {
    switch (this.serviceOrderCard.status) {
      case 1:
        return this.faAwaitingOrder
      case 2:
        return this.faInProgressOrder
      case 3:
        return this.faFinishOrder
      case 4:
        return this.faCanceledOrder
      default:
        return this.faEllipsis
    }
  }

  getClassStatusServiceOrder() {
    return {
      'em-espera': this.serviceOrderCard.status === 1,
      'em-progresso': this.serviceOrderCard.status === 2,
      'finalizada': this.serviceOrderCard.status === 3,
      'cancelada': this.serviceOrderCard.status === 4
    }
  }


  getTextStatusCard() {
    return getTextStatusServiceOrder(this.serviceOrderCard.status)
  }

  setNewStatus(
    {osID, newStatus}: {osID: number, newStatus: number}
    ){
    this._serviceOrderService.changeStatus(osID, newStatus)
    .pipe(
      catchError((err: any) => {
        throw 'Erro: ' + err
      }),

    )
    .subscribe(
      {
        next: () =>  {
          this._serviceOrderService.clearCacheServ()
          this._bottomSheet.open(SucessBottomSheetComponent,
            {
              data: 'Status alterado!'
            })
        },  
        error: (errorData:any) =>  {
          this._serviceOrderService.clearCacheServ()
          this._bottomSheet.open(ErrorBottomSheet, 
            { data: 
              {
                redirectTo: 'ordem-servico',
                error: errorData
              } 
            })
        }
      }
    )
  }

  handleStatusChange(status: number) {
    const requestParams = {
      osID: this.serviceOrderCard.id, newStatus: status
    }
    if (typeof this.serviceOrderCard.chip === "string" || 
        typeof this.serviceOrderCard.veiculo === "string" ||
        this.serviceOrderCard.equipamento_modelo.includes("deletado") ){
        this._bottomSheet.open(ErrorBottomSheet, 
          { data: 
            {
              redirectTo: 'ordem-servico',
              error:  {message: 'Item vinculado a OS não está mais disponível.'}
            } 
          })  
      }
    else {this.setNewStatus(requestParams)}
  } 

  openCheklistModal(os: ServiceOrder) {
    const dialogRef = this.dialog.open(ChecklistComponent,
      { 
        panelClass: 'fullscreen-dialog',
        data: os ,
      }
    );
  }
}
