import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError } from 'rxjs';
import { ErrorBottomSheet } from 'src/app/features/helpers';
import { SucessBottomSheetComponent } from 'src/app/features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { AccountModel } from '../../../admin/account';
import { AdminService } from '../../../admin/admin.service';
import { ProfileModel } from '../../user';
import { UserService } from '../../user.service';




@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent extends BaseComponentComponent implements OnInit,OnChanges {

  UserForm: FormGroup;

  @Input() user: any = null
  @Output() hideComponent = new EventEmitter()

  profilesData: ProfileModel[] 

  constructor(
    private _adminService: AdminService,
    private _bottomSheet: MatBottomSheet,
    private _userService: UserService
  ) { 

    super()

    this.initForm()
  }
    
  ngOnInit(): void {
    this.getProfiles()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm() {
    this.UserForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('',Validators.compose([Validators.required, Validators.email])),
      perfil_id: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    })
  }

  getProfiles() {
    this._userService.profileListEventEmmiter.subscribe((data) => {
      this.profilesData = data
    })
    this._userService.getAccessProfiles()
  }

  cancel():void {
    this.hideComponent.emit('')
  }

  handleSaveUser(): void {

    if (this.UserForm.valid) {

        let newUser : any = {
          nome: this.UserForm.controls['name'].value,
          email: this.UserForm.controls['email'].value,
          password: this.UserForm.controls['password'].value,
          perfil_id: this.UserForm.controls['perfil_id'].value,
          cliente_conor_id: this.user.cliente_conor_id
        }

        this._userService
          .saveNewUser(newUser)
          .pipe(
            catchError((err: any) => {
              throw err
            }),
          )
          .subscribe(
            {
              next: () =>  {
                this._bottomSheet.open(SucessBottomSheetComponent,
                  {
                    data: 'Usuário cadastrado!',
                  }
                )
                this.hideComponent.emit({reload:true})
              },
              error: (errorData:any) =>  {
                this._bottomSheet.open(ErrorBottomSheet, 
                  { data: 
                    {
                      redirectTo: 'dashboard',
                      error: errorData
                    } 
                  })
              } 
            }
          )

      } else {
        this.UserForm.markAllAsTouched()
      }
      
    }
  
}
