import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { ClientArea } from '../client-area.service';
import { catchError } from 'rxjs';

interface BaseVisualIdentityInfo {
  id: number
  primaryColor: string
  secondaryColor: string
  fontColor: string
  logoUrl: string | null
  name: string
}

export interface SearchParams {
  cpf: string,
  baseId: number
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponentComponent implements OnInit {

  routeParamId: string | null = null
  inputCPF!: FormControl
  hasError:boolean = false
  loading:boolean = false
  picture:string = '../../../../assets/images/no-picture-provided.png'
  visualIdentity: BaseVisualIdentityInfo | null = null
  baseDataHash : string = ''
  invalidInput: boolean = false

  constructor(
    private _activeRoute: ActivatedRoute,
    private _route: Router,
    private _clientAreaServices: ClientArea,
  ) { 
      super()

      this.inputCPF = new FormControl('')
  }

  getBaseData(id:string) {
    if(this.routeParamId) {
      this._clientAreaServices.getBaseById(id).subscribe(
        (res: string) => {
          this.baseDataHash = res
          let decoded = this._clientAreaServices.decodeJwtToken(res)
          this.visualIdentity = decoded.sub as BaseVisualIdentityInfo

          if(this.visualIdentity?.logoUrl) {
            this.picture = this.visualIdentity?.logoUrl
          }
        }
      )
    }
  }

  showErrorMessage() {
    this.hasError = true

    setTimeout(()=> {
      this.hasError = false
    },2000)
  }

  handleFindClient() {
    this.loading = true

    if(this.inputCPF.valid){

      let params: SearchParams = {
        cpf: this.inputCPF.value,
        baseId:  this.visualIdentity!.id
      }

      this._clientAreaServices.getClientByCpf(params).pipe(
        catchError((err: any) => {
          this.invalidInput = true
          throw err
        }),
      )
      .subscribe((res)=> {
        console.log(res)
        this._route.navigate(['area-cliente/charges', res, this.baseDataHash ]);
      })
    }else {
      this.showErrorMessage()
      this.inputCPF.markAsTouched
    }
  }

  ngOnInit(): void {
    this.routeParamId = this._activeRoute.snapshot.paramMap.get('id');

    if(this.routeParamId){ 
      this.getBaseData(this.routeParamId)
    }
  }
}
