<!-- <div class="wrapper d-flex align-items-stretch"> -->
<!-- </div> -->

<app-loader></app-loader>

<div id="app"  [ngClass]="authService.isLogged() ? 'system' : ''">

    <router-outlet></router-outlet>

</div>
