import {Component, EventEmitter, OnInit} from '@angular/core';
import { BreakpointObserver,Breakpoints} from '@angular/cdk/layout';
import { AppResponsive} from "../../app.module";
import {Observable, of} from "rxjs";
import {EquipmentModel} from "../../features/equipment/equipment/equipment";


@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss']
})
export class BaseComponentComponent   {


    private _responsive : BreakpointObserver

    public isPhone: boolean = true;

    public changePhoneEventEmitter: EventEmitter<Boolean> =
        new EventEmitter()

  constructor() {


      this._responsive = AppResponsive;

      this._responsive.observe(Breakpoints.Handset)
          .subscribe(result => {

              if (result.matches) {
                this.isPhone  = true;



              } else {
                  this.isPhone  = false;

              }



              this.changePhoneEventEmitter.emit(this.isPhone)

          });
  }



}

