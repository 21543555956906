import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {ToolBarConfig} from "./toolbar/toolbar";

import {BaseComponentComponent} from "../base-component/base-component.component";
import { AuthService } from 'src/app/features/auth/auth.service';
import { AdminSidebarComponent } from './sidebar-admin/sidebar-admin';


@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminHeaderComponent extends BaseComponentComponent implements OnInit {
  faSearch = faSearch


  public headerConfig! : ToolBarConfig;

  @ViewChild('adminSidebar')
  public adminSideBar!: AdminSidebarComponent

  constructor(public authService: AuthService) {
    super();

  }


  ngOnInit(): void {


  }
}
