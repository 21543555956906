import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/features/auth/auth.service';

@Directive({
  selector: '[accountBreakpointVersion]'
})
export class AccountBreakpointVersion implements OnInit {

  @Input() menuCheck: string = '';

  constructor(
    private _authService: AuthService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) { }

  hideElement(htmlReference:any) {
    this._elementRef.nativeElement
    this._renderer.setStyle(htmlReference, 'display', 'none');
  }

  ngOnInit(): void {

      const tokenData = this._authService.decodeJwtToken(this._authService.token)

      const legado_status = tokenData.sub.legado 

      if (this.menuCheck) {
        if (legado_status) this.hideElement(this._elementRef.nativeElement)
      }else {
        if (legado_status == false) this.hideElement(this._elementRef.nativeElement)
      }

  }

}
