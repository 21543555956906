import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import { MatDialog } from '@angular/material/dialog';
import {Router} from "@angular/router";
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-sucess-bottom-sheet',
  templateUrl: './sucess-bottom-sheet.component.html',
  styleUrls: ['./sucess-bottom-sheet.component.scss']
})
export class SucessBottomSheetComponent  {

  message : any = ''

  constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private _bottomSheetRef: MatBottomSheetRef<SucessBottomSheetComponent>,
      private readonly router: Router,
      private dialog: MatDialog,
      private adminService: AdminService
  ) {
    this.message = data;
  }

  getErrorMessage( errorObject: any ): void {

    let message = Object.values(errorObject.error)[0] as string
    this.message =  message

  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    this._bottomSheetRef.afterDismissed();
    event.preventDefault();
  }

  next (): void{

    this._bottomSheetRef.dismiss();

  }

  close (): void {

    this._bottomSheetRef.dismiss();

  }

}
