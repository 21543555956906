export enum ACCESS_CONTROL_TYPES {
    ROLE_PANEL_READ = "ROLE_PANEL_READ",
    
    ROLE_CUSTOMERS_READ = "ROLE_CUSTOMERS_READ",
    ROLE_CUSTOMERS_UPDATE = "ROLE_CUSTOMERS_UPDATE",
    ROLE_CUSTOMERS_DELETE = "ROLE_CUSTOMERS_DELETE",

    //financial menu
    ROLE_FINANCIAL_MENU_READ = "ROLE_FINANCIAL_MENU_READ", 

    ROLE_CHARGES_READ = "ROLE_CHARGES_READ",
    ROLE_CHARGES_UPDATE = "ROLE_CHARGES_UPDATE",
    ROLE_CHARGES_DELETE = "ROLE_CHARGES_DELETE",

    ROLE_STATEMENT_READ = "ROLE_STATEMENT_READ",
    ROLE_STATEMENT_UPDATE = "ROLE_STATEMENT_UPDATE",

    ROLE_DUNNINGS_READ = "ROLE_DUNNINGS_READ",
    ROLE_DUNNINGS_UPDATE = "ROLE_DUNNINGS_UPDATE",
    ROLE_DUNNINGS_DELETE = "ROLE_DUNNINGS_DELETE",

    //operational menu
    ROLE_OPERATIONAL_READ = "ROLE_OPERATIONAL_READ", //SIDEBAR OP MENU
    ROLE_DEVICES_READ = "ROLE_DEVICES_READ", //SIDEBAR DEVICES MENU

    ROLE_EQUIPMENT_READ = "ROLE_EQUIPMENT_READ",
    ROLE_EQUIPMENT_UPDATE =  "ROLE_EQUIPMENT_UPDATE",
    ROLE_EQUIPMENT_DELETE =  "ROLE_EQUIPMENT_DELETE",

    ROLE_CHIPS_READ = "ROLE_CHIPS_READ",
    ROLE_CHIPS_UPDATE = "ROLE_CHIPS_UPDATE",
    ROLE_CHIPS_DELETE = "ROLE_CHIPS_DELETE",

    ROLE_VEHICLES_READ = "ROLE_VEHICLES_READ",
    ROLE_VEHICLES_UPDATE = "ROLE_VEHICLES_UPDATE",
    ROLE_VEHICLES_DELETE = "ROLE_VEHICLES_DELETE",

    ROLE_SERVICE_ORDERS_READ = "ROLE_SERVICE_ORDERS_READ",
    ROLE_SERVICE_ORDERS_UPDATE = "ROLE_SERVICE_ORDERS_UPDATE",
    ROLE_SERVICE_ORDERS_DELETE = "ROLE_SERVICE_ORDERS_DELETE",

    ROLE_TECHNICIANS_READ = "ROLE_TECHNICIANS_READ",
    ROLE_TECHNICIANS_UPDATE = "ROLE_TECHNICIANS_UPDATE",
    ROLE_TECHNICIANS_DELETE = "ROLE_TECHNICIANS_DELETE",

    ROLE_PRODUCTS_READ = "ROLE_TECHNICIANS_READ",
    ROLE_PRODUCTS_UPDATE = "ROLE_TECHNICIANS_UPDATE",
    ROLE_PRODUCTS_DELETE = "ROLE_TECHNICIANS_DELETE",

};