import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SearchParams } from './home/home.component';
import { PageParams } from '../customer/detail-customer/financeiro/financeiro.component';

@Injectable({
  providedIn: 'root'
})
export class ClientArea {

  constructor(
    private _http: HttpClient,
    private jwtHelper: JwtHelperService,
    private httpClient: HttpClient
    ) { }

  decodeJwtToken(token:string): any {
    return this.jwtHelper.decodeToken(token)
  }


  getBaseById(baseId:string): Observable<any> {
    return this._http
      .get(environment.apiUrl + '/client-area/'+baseId)
  }



  getClientByCpf(args: SearchParams): Observable<any> {
    let params = new HttpParams()
    .set('cpf', args.cpf)
    .set('baseId', args.baseId);

    return this._http
    .get(environment.apiUrl + '/client-area', {params})
  }

  public getClientCharges(id:string, pageParams:PageParams): Observable<any> {

    let params = new HttpParams()
    .set('page', pageParams.page)
    .set('page_size', pageParams.page_size)

    return this.httpClient
    .get(environment.apiUrl + '/client-area/cobrancas/'+ id, {params})
  }
}
