import { Subject } from 'rxjs'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponentComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<boolean>()

  constructor() {
    super()
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }
}
