import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OsComponent } from './os/os.component'
import { AuthComponent } from '../auth/login/auth.component'
import { TokenAuthorized } from 'src/app/core/guards/token-authorized.guard'


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
  }, 
  {
    path: 'os/:token',
    component: OsComponent,
    canActivate: [TokenAuthorized]
  },

]

//TokenAuthorized

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [TokenAuthorized] 
})
export class ExternalLinkRoutingModule {}