<section
  id="createNewOSModal"
  [ngClass]="{'phone-container': isPhone, 'input-box': 'container modal-create-crud-aw'}"
  style=" background: #FAF9F7;"
  >
  <div class="close-icon-container" (click)="handleCloseModal()">
      <mat-icon class="close-icon" inline=true svgIcon="close-tab-icon" mat-list-icon >
      </mat-icon>
  </div>

  <mat-stepper [orientation]="isPhone? 'vertical' : 'horizontal'" #stepper class="stepper" [linear]="false">
    <mat-step
      [stepControl]="createServiceOrder"
      [editable]="true">
      <form [formGroup]="createServiceOrder" id="step-one">
          <ng-template matStepLabel> OS</ng-template>
          <h1 class="title">{{isEdit? 'Editar': 'Nova'}} ordem de serviço</h1>
          <mat-form-field appearance="outline" color="accent">
              <mat-label>Nome da OS</mat-label>
              <input matInput placeholder="Nome" formControlName="title">
              <mat-error
                *ngIf="createServiceOrder.controls['title'].getError('required')"
                >Nome é obrigatório
              </mat-error>
          </mat-form-field>

          <mat-form-field class="deadline" appearance="outline">
            <mat-label>Prazo</mat-label>
            <input #testes matInput formControlName="deadline" [min]="minDate" [matDatepicker]="picker">
            <mat-hint>DD/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error  *ngIf="createServiceOrder.controls['deadline'].getError('required')  && createServiceOrder.controls['deadline'].touched">Obrigatório informar o prazo
            </mat-error>
          </mat-form-field>


          <div>
              <button mat-button matStepperNext color="primary">Próximo</button>
          </div>
      </form>
    </mat-step>

    <mat-step
      [stepControl]="customerForm"
      [editable]="true">
      <form [formGroup]="customerForm" id="step-one">

        <ng-template matStepLabel>Cliente</ng-template>
        <h1 class="title">Detalhes do cliente</h1>
        <div class="form-container">

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Nome do cliente</mat-label>
          <input
            matInput
            placeholder="Selecione um cliente"
            formControlName="customerName"
            [(ngModel)]="customerSelected"
            [matAutocomplete]="auto"
            (optionSelected)="onCustomerSelected($event)"
          >
          <mat-error 
            *ngIf="customerForm.controls['customerName'].invalid">
            Você deve selecionar através da lista
          </mat-error>

          <mat-autocomplete customerAutoC (optionSelected)="onCustomerSelected($event)" #auto="matAutocomplete"  [displayWith]="displayCustomer">
            <mat-option *ngFor="let customer of filteredCustomerOptions" [value]="customer" >
                {{customer.nome}}
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="customerForm.controls['customerName'].getError('required')"
            >Nome é Obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field id="datetime-input" [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Data/Horário </mat-label>
          <input
            placeholder="Selecione uma Data/horario"
            matInput
            type="datetime-local"
            [min]="currentISOTime"
            placeholder="Técnico"
            formControlName="dateTime"
            [(ngModel)]="dateTimeScheduled"
            >
          <mat-error
            *ngIf="customerForm.controls['dateTime'].getError('required')"
            >Selecione uma data e horário.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>CEP</mat-label>
          <input matInput
          placeholder="CEP"
          formControlName="cep"
          mask="00000-000"
          (blur)="handleSearchCep()">
          <mat-error
            *ngIf="customerForm.controls['cep'].getError('required')"
            >informe o cep.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Numero</mat-label>
          <input matInput placeholder="Numero" formControlName="addressNumber">
          <mat-error
            *ngIf="customerForm.controls['addressNumber'].getError('required')"
            >Número é obrigatório.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Endereço</mat-label>
          <input matInput placeholder="Endereço" formControlName="address">
          <mat-error
            *ngIf="customerForm.controls['address'].getError('required')"
            >Informe o endereço.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Bairro</mat-label>
          <input matInput placeholder="Bairro" formControlName="neighbourAddress">
          <mat-error
            *ngIf="customerForm.controls['neighbourAddress'].getError('required')"
            >Bairro é obrigatório.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Cidade</mat-label>
          <input matInput placeholder="Cidade" formControlName="city">
          <mat-error
            *ngIf="customerForm.controls['city'].getError('required')"
            >Cidade é obrigatório.
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
          <mat-label>Estado</mat-label>
          <input matInput placeholder="Cidade" formControlName="state">
          <mat-error
            *ngIf="customerForm.controls['state'].getError('required')"
            >Informe o estado.
          </mat-error>
        </mat-form-field>

        </div>
      </form>
      <div>
        <button mat-button matStepperPrevious color="primary">Voltar</button>
        <button mat-button matStepperNext color="primary">Próximo</button>
      </div>
    </mat-step>

    <mat-step
      [stepControl]="serviceInformations"
      [editable]="true">
      <form [formGroup]="serviceInformations" id="step-two">
        <ng-template matStepLabel>Serviço</ng-template>
        <h1 class="title">Detalhes do serviço</h1>
        <mat-form-field  [style.width.%]="100" appearance="outline" color="accent">
            <mat-label>Tipo de serviço</mat-label>
            <mat-select formControlName="serviceType" placeholder="Selecione">
              <mat-option value="0"> Instalação </mat-option>
              <mat-option value="1"> Manutenção </mat-option>
              <mat-option value="2"> Retirada </mat-option>
            </mat-select>
            <mat-error
              *ngIf="serviceInformations.controls['serviceType'].getError('required')"
              >Tipo de serviço é obrigatório
            </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.%]="100" appearance="outline" >
          <mat-label>Descrição</mat-label>
          <textarea
          matInput
          formControlName="obs"></textarea>
        </mat-form-field>
          <div id="double-input">
            <mat-form-field [style.width.%]="100"  appearance="outline" color="accent">

              <mat-label>Placa veículo</mat-label>
              <mat-select [(ngModel)]="vehicleSelected" required formControlName="licensePlate" (selectionChange)="onVehicleSelected()" placeholder="Placa do veículo">
                <mat-option *ngFor="let vehicle of filteredVehicleOptions" [value]="vehicle" >
                  {{vehicle.placa}}
                </mat-option>
                <mat-error
                *ngIf="serviceInformations.controls['licensePlate'].getError('required')"
                >Equipamento é Obrigatório
                </mat-error>
              </mat-select>

            </mat-form-field>

            <mat-form-field  [style.width.%]="100" appearance="outline" color="accent">
              <mat-label>Modelo veículo</mat-label>
              <input

                matInput
                [disabled]="true"
                placeholder="Modelo do veículo"
                formControlName="vehicleModel"
               >

              <mat-error
                *ngIf="serviceInformations.controls['vehicleModel'].getError('required')"
                >Modelo é obrigatório
              </mat-error>
            </mat-form-field>
          </div>
          <div id="double-input-70-30">
            <mat-form-field  [style.width.%]="100" appearance="outline" color="accent">


              <mat-label>Equipamento</mat-label>
              <mat-select required formControlName="equipment" [(ngModel)]="equipmentSelected" placeholder="Equipamento">
                <mat-option *ngFor="let equipment of filteredEquipmentOptions" [value]="equipment" >
                  {{equipment.modelo + ' | ' + equipment.imei}}
                </mat-option>
                <mat-error
                *ngIf="serviceInformations.controls['equipment'].getError('required')"
                >Equipamento é Obrigatório
              </mat-error>
              </mat-select>

            </mat-form-field>

            <mat-form-field  [style.width.%]="100" appearance="outline" color="accent">
              <mat-label>Chip</mat-label>

              <mat-select required formControlName="chip" [(ngModel)]="chipSelected" placeholder="Chip">
                  <mat-option *ngFor="let chip of filteredChipOptions" [value]="chip" >
                {{ chip.numero_linha }}
                  </mat-option>
              </mat-select>

              <mat-error
                *ngIf="serviceInformations.controls['equipment'].getError('required')"
                >Chip é Obrigatório
              </mat-error>
            </mat-form-field>
          </div>

        <div>
          <button mat-button matStepperPrevious color="primary">Voltar</button>
          <button mat-button matStepperNext color="primary">Próximo</button>
        </div>
      </form>
    </mat-step>

    <mat-step
      [stepControl]="technicianForm"
      [editable]="true">
      <form [formGroup]="technicianForm" id="step-three">
        <ng-template matStepLabel>Tecnico</ng-template>
        <h1 class="title">Tecnico alocado</h1>
        <div id="double-input">
          <mat-form-field [style.width.%]="100" appearance="outline" color="accent">
              <mat-label>Tecnico</mat-label>
              <mat-select required formControlName="technician" [(ngModel)]="techSelected" placeholder="Técnico">
                <mat-option *ngFor="let technician of filteredTechnicianOptions" [value]="technician" >
              {{ technician.nome }}
                </mat-option>

            </mat-select>
            <mat-error  *ngIf="technicianForm.controls['technician'].getError('required')  && technicianForm.controls['technician'].touched">Técnico é obrigatório.
            </mat-error>


          </mat-form-field>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Valor</mat-label>
            <input matInput
              formControlName="price"
              [(ngModel)]="value"
              currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',  align:'left'}"
              placeholder="0,00"
            >
            <mat-error  *ngIf="technicianForm.controls['price'].getError('required')  && technicianForm.controls['price'].touched">Orçamento é obrigatório.
            </mat-error>
          </mat-form-field>
          <mat-error class="form-error" *ngIf="ErrorStep">
            Todas as etapas devem ser preenchidas corretamente.
          </mat-error>
        </div>

      </form>

      <div class="finish-btn-container">

        <button mat-button matStepperPrevious color="primary">Voltar</button>
        <button mat-raised-button color="primary" (click)="handleSaveBtn()">Concluir</button>
      </div>
    </mat-step>

  </mat-stepper>
</section>

