import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/features/auth/auth.service';

@Directive({
  selector: '[userRole]'
})
export class UserPermissionsDirective implements OnInit {
  @Input() permission: string;
  @Input() action: string = 'display none'

  constructor(
    private _authService: AuthService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {

      const perfil = this._authService.getPerfil()
      const { permissoes } = perfil

      const isAuthorized = (): boolean => {
        return permissoes.includes(this.permission)
      } 

      let cssAtribute = this.action.split(' ')[0]
      let cssValue = this.action.split(' ')[1]

      if ( !isAuthorized() ) {
        this._renderer.setStyle(
          this._elementRef.nativeElement, 
          cssAtribute, 
          cssValue
        );
      }
  }

}
