<div class="card">
    <span class="bold-text">Acesso a area do cliente: </span>
    <div class="link-container">
        <a  href={{clientAreaLink}}> {{clientAreaLink}} </a>
        <button mat-stroked-button (click)="handleCopyText()">
            <mat-icon 
                mat-icon-button  
                matTooltip="Copiar para a área de transferência" 
                matTooltipPosition="above">file_copy
            </mat-icon>
        </button>

    </div>
</div>