import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToolbarComponent } from './toolbar/toolbar.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialExampleModule } from '../../../material.module'
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header.component";
import { RouterModule } from "@angular/router";
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [ToolbarComponent, SidebarComponent, HeaderComponent],
  imports: [CommonModule, FontAwesomeModule, MatToolbarModule,  MaterialExampleModule, RouterModule, DirectivesModule],
  exports: [HeaderComponent]
})
export class HeaderModule {}
