import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToolbarComponent } from './toolbar/toolbar.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatToolbarModule } from '@angular/material/toolbar';
import {MaterialExampleModule} from '../../../material.module'
import {AdminHeaderComponent} from "./header-admin.component";
import {RouterModule} from "@angular/router";
import { AdminSidebarComponent } from './sidebar-admin/sidebar-admin';



@NgModule({
  declarations: [ToolbarComponent, AdminHeaderComponent, AdminSidebarComponent],
  imports: [CommonModule, FontAwesomeModule, MatToolbarModule,  MaterialExampleModule, RouterModule],
  exports: [AdminHeaderComponent]
})
export class HeaderAdminModule {}
