import { Component, OnInit } from '@angular/core';
import { ServiceOrderModule } from '../../service-order/service-order.module';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ServiceOrderService } from '../../service-order/service-order.service';

@Component({
  selector: 'app-os',
  templateUrl: './os.component.html',
  styleUrls: ['./os.component.scss']
})
export class OsComponent implements OnInit {

  public serviceOrderId:number | null  = null
  public serviceOrderModel: ServiceOrder
  public accesstoken:string | null = null

  constructor( 
    private _activeRoute: ActivatedRoute, 
    private _jwtHelper: JwtHelperService,
    private _ServicesOS: ServiceOrderService
    ) { }

  getOrderId() {
    this.accesstoken = this._activeRoute.snapshot.paramMap.get('token');
    const hashDecoded = this._jwtHelper.decodeToken(this.accesstoken as string)

    return hashDecoded.sub.id
  }

  fetchServiceOrder() {

    if(this.serviceOrderId) this._ServicesOS.getServiceOrderById(this.serviceOrderId).subscribe((data)=> {
      this.serviceOrderModel = data

    })
  }
  
  ngOnInit(): void {
    this.serviceOrderId = this.getOrderId()
    if(this.serviceOrderId) this.fetchServiceOrder()
  }

}
