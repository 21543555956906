import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/features/auth/auth.service';

@Directive({
  selector: '[adminProfileCheck]'
})
export class AdminProfileCheck implements OnInit {
  adminProfileId: number = 1;

  constructor(
    private _authService: AuthService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {

      const perfil = this._authService.getPerfil()

      const isAuthorized = (): boolean => {
        return perfil.id === this.adminProfileId
      } 

      if ( !isAuthorized() ) {
        const element =  this._elementRef.nativeElement
        this._renderer.setStyle(element, 'display', 'none');
      }
  }

}
