export function translateChargeStatusName(status: string) {

    var statusReturn = ""

    switch (status) {

        case "PENDING" :
            statusReturn = "Aguardando pagamento"
            break;
        case "RECEIVED" :
            statusReturn = "Recebida"
            break;
        case "OVERDUE" :
            statusReturn = "Vencida"
            break;
        case "CONFIRMED" :
            statusReturn = "Pagamento confirmado"
            break;
        case "ACTIVE" :
            statusReturn = "Ativo"
            break;
        case 'INACTIVE' :
            statusReturn = "Inativo";
            break
        case "RECEIVED_IN_CASH" :
            statusReturn = "Pago em espécie"
            break;
        case "REFUND_REQUESTED" :
            statusReturn = "Estorno Solicitado"
            break;
        case "REFUNDED" :
            statusReturn = "Estornada"
            break;
        case "CHARGEBACK_REQUESTED" :
            statusReturn = "Estorno Solicitado"
            break;
        case "CHARGEBACK_DISPUTE" :
            statusReturn = "Em disputa de estorno"
            break;
        case "AWAITING_CHARGEBACK_REVERSAL" :
            statusReturn = "Disputa estorno vencida"
            break;
        case "DUNNING_REQUESTED" :
            statusReturn = "Em processo de negativação"
            break;
        case "DUNNING_RECEIVED" :
            statusReturn = "Recuperada"
            break;
        case "AWAITING_RISK_ANALYSIS" :
            statusReturn = "Pagamento em análise"
            break;
        default:
            statusReturn = status;

    }

    return statusReturn;

}