import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const { message } = error
        this.openSnack(message)
        // this._toastService.show(message || 'Ocorreu um erro, tente novamente mais tarde',
        // { classname: 'bg-danger text-light', delay: 10000 });
        return throwError(error)
      })
    )
  }

  openSnack(message: string) {
    //TODO: Create a snack with alert bootstrap
    console.log(message)
  }
}
