import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(
    private _httpClient: HttpClient
    ) {}

  getJWTToken(osId:number): Observable<any> {
    return this._httpClient.get(environment.apiUrl + '/external/service-order/token/'+osId)
  }

}
