import { Component, Input, OnInit } from '@angular/core'
import {
  faHammer,
  faCalendar,
  faUserAlt,
  faBox,
  faPaperclip,
  faClock,
  faSatelliteDish,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { DateFormat, MoneyFormat } from 'src/app/shared/utils/formats'

@Component({
  selector: 'app-info-card-service-order',
  templateUrl: './info-card-service-order.component.html',
  styleUrls: ['./info-card-service-order.component.scss']
})
export class InfoCardServiceOrderComponent implements OnInit {
  faServiceType = faHammer
  faExecutionDate = faCalendar
  faClient = faUserAlt
  faBudget = faBox
  faAttachment = faPaperclip
  clockIcon = faClock
  faSatellitedish = faSatelliteDish
  teste = faUser


  @Input()
  infoServiceOrder: Omit<ServiceOrder, 'id | title'> = {} as Omit<
    ServiceOrder,
    'id | title'
  >

  constructor() {}

  ngOnInit(): void {
  }

  getClassStatusServiceOrder() {
    return {
      'em-espera': this.infoServiceOrder.status === 1,
      'em-progresso': this.infoServiceOrder.status === 2,
      finalizada: this.infoServiceOrder.status === 3,
      cancelada: this.infoServiceOrder.status === 4
    }
  }

  formatDate(date: string) {
    return DateFormat(date)
  }

  renderFormatDateTime(dateTime: string): {date:string, time:string} {

    let d = new Date(dateTime)

    let dateFormatted = [
      d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate(),
      (d.getUTCMonth() + 1) < 10 ? '0' + (d.getUTCMonth() + 1) : (d.getUTCMonth() + 1),
      d.getUTCFullYear()
    ].join('/');
  
    let hour = d.getUTCHours();
    let minutes = d.getUTCMinutes();
    let timeFormatted = [
      hour < 10 ? '0' + hour : hour,
      minutes < 10 ? '0' + minutes : minutes
    ].join(':');

    return {
      date: dateFormatted,
      time: timeFormatted
    }
  }

  formatMoney(money: string) {
    const numberMoney = parseFloat(money)
    return MoneyFormat(numberMoney)
  }
}
