<div class="container mt-4">
  <form
    [formGroup]="loginForm"
    (ngSubmit)="submit()"
    class="d-flex flex-column"
  >
    <div class="row mb-3">
      <div id="div-email" class="col">
    
        <input
          class="form-control form-control-md"
          placeholder="Usuário"
          id="email-login-input"
          type="email"
          formControlName="email"
          [ngClass]="{
            'is-invalid': submitLoginForm && loginForm.controls['email'].errors
          }"
        />
        <div
          *ngIf="submitLoginForm && loginForm.controls['email'].errors"
          class="invalid-feedback"
        >
          <div
            id="required-field-message"
            *ngIf="
              submitLoginForm && loginForm.controls['email'].errors['required']
            "
          >
            Campo obrigatório.
          </div>
          <div
            id="required-field-message"
            *ngIf="loginForm.controls['email'].errors['email']"
          >
            Email inválido.
          </div>
        </div>
      </div>
    </div>

    
    <div class="row">
      <div id="div-password" class="col input-group">
        <input
          class="form-control"
          id="senha-login-input"
          placeholder="Senha"
          [type]="showPasswordControl ? 'text' : 'password'"
          [ngClass]="{
            'is-invalid':
              submitLoginForm && loginForm.controls['password'].errors
          }"
          formControlName="password"
        />
        <span
          class="input-group-append border-0 input-icon"
          (click)="showPassword()"
        >
          <span class=" input-group-text bg-transparent">
            <fa-icon class="eye-icon"
              [icon]="showPasswordControl ? faEye : faEyeSlash"
            ></fa-icon>
          </span>
        </span>
        <div
          *ngIf="submitLoginForm && loginForm.controls['password'].errors"
          class="invalid-feedback"
        >
          <div
            id="required-field-message"
            *ngIf="
              submitLoginForm &&
              loginForm.controls['password'].errors['required']
            "
          >
            Campo obrigatório.
          </div>
        </div>
      </div>
    </div>
    <div class="forgot-password-container">
      <div class="row d-block btn-login mt-2 px-2">
        <button class="w-100 btn btn-primary" id="login-button">Entrar</button>
      </div>

      <label for="forgot-password" class="link-forgot-password mt-2">
        <button (click)="handleForgotPassword()" class="forgot-passoword-btn">
          Esqueceu a senha?
        </button> 
      </label>

    </div>
  </form>
</div>

<app-modal #modal [modalConfig]="modalConfig">
  {{ modalMessage }}
</app-modal>
