<div class="main-body" [ngClass]="{'main-phone': isPhone }">
    <h5>Cadastrar novo usuário </h5>
    <form [formGroup]="UserForm">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Digite o nome do usuário" formControlName="name">
                <mat-error *ngIf="UserForm.controls['name'].getError('required')
            ">Nome é obrigatorio
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="ex.: name123@dominio.com" formControlName="email">
                <mat-error *ngIf="UserForm.controls['email'].getError('required')
            ">Email é obrigatorio
                </mat-error>
                <mat-error *ngIf=" UserForm.controls['email'].getError('email')
                && UserForm.controls['email'].touched">E-mail invalido
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>Perfil de autorizações</mat-label>
                <mat-select formControlName="perfil_id" placeholder="Selecione um perfil">
                    <mat-option *ngFor="let profile of profilesData" [value]="profile.id">{{profile.nome}}</mat-option>
                </mat-select>
                <mat-error *ngIf="UserForm.controls['name'].getError('required')
                ">Selecionar perfil é obrigatório.
                    </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <input type="password" matInput placeholder="digite uma senha" formControlName="password">
                <mat-error *ngIf="UserForm.controls['password'].getError('required')
            ">Senha é obrigatoria
                </mat-error>
                <mat-error *ngIf=" UserForm.controls['password'].getError('password')
                && UserForm.controls['password'].touched">Senha inválida
                </mat-error>
            </mat-form-field>
        </div>

    </form>
    <div class="btn-container">
        <button mat-raised-button color="primary" (click)="cancel()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="handleSaveUser()">Cadastrar</button>
    </div>
</div>