import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ClientAreaRoutingModule } from './client-area-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MaterialExampleModule } from 'src/material.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { ClientChargesComponent } from './client-charges/client-charges.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [ HomeComponent, ClientChargesComponent ],
  imports: [
    ClientAreaRoutingModule,
    CommonModule,
    MatIconModule,
    MaterialExampleModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    PipesModule,
    MatExpansionModule
  ]
})
export class ClientAreaModule { }
