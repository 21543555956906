import {
  faTimesCircle,
  faHourglass,
  faSpinner,
  faClipboardCheck
} from '@fortawesome/free-solid-svg-icons'

export function getTextStatusServiceOrder(value: number) {
  switch (value) {
    case 1:
      return 'Em Espera'
    case 2:
      return 'Em Progresso'
    case 3:
      return 'Finalizada'
    case 4:
      return 'Cancelada'
    case 300:
      return 'arquivada'
    default:
      return
  }
}

export function getIconForServiceOrder(status: number) {
  switch (status) {
    case 1:
      return faHourglass
    case 2:
      return faSpinner
    case 3:
      return faClipboardCheck
    case 4:
      return faTimesCircle
    default:
      return faSpinner
  }
}

