import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { translateBillingType } from 'src/app/shared/utils/billingTypeTranslate';
import { translateChargeStatusName } from 'src/app/shared/utils/translateChargeStatus';
import { PageParams } from '../../customer/detail-customer/financeiro/financeiro.component';
import { ClientArea } from '../client-area.service';
import { isJwtExpValid } from '../../../shared/utils/JWT'
import { EventUpdateDataService } from 'src/app/shared/Events/event-update-data.service';


@Component({
  selector: 'client-charges-bills',
  templateUrl: './client-charges.component.html',
  styleUrls: ['./client-charges.component.scss']
})
export class ClientChargesComponent extends BaseComponentComponent implements OnInit {

  clientId: string = ''
  hash: any = ''
  isJWTValid: boolean = false
  clientName:string = ''
  picture:string = '../../../../assets/images/no-picture-provided.png'

  chargeListParams: PageParams = {
    page: 1 ,
    page_size: 10,
  }

  dataSource: any = []
  constructor(
        private _activeRoute: ActivatedRoute,
        private _clientAreaService:  ClientArea,
        private readonly _eventUpdateDataService: EventUpdateDataService,
  ) {
    super()

    this._eventUpdateDataService.onDataUpdated$.subscribe(() => {
      this.fetchCharges();
    });
  }

  translateType(billingType: any): string {
    var statusReturn = translateBillingType(billingType)
    return statusReturn;
  }

  translateStatus(event:any): string {
    let translatedStatus:string = translateChargeStatusName(event)
    return  translatedStatus
  }

  handleBoletoView(charge: any) {

    if (charge.billingType == 'CREDIT_CARD' || charge.billingType == 'PIX') {
        window.open(charge.invoiceUrl, '_blank')
    } else {

        window.open(charge.bankSlipUrl, '_blank')
    }

  }

  onPageChange($event: PageEvent) {

    this.chargeListParams.page = $event.pageIndex + 1
    this.fetchCharges()

  }

  fetchCharges() {
    this._clientAreaService.getClientCharges(this.clientId, this.chargeListParams ).subscribe((res)=> {
      this.clientName = res.name.split(' ')[0];
      this.dataSource = res;
    })
  }

  getPathParams() {

    const id = this._activeRoute.snapshot.paramMap.get('id') 
    const pathHash = this._activeRoute.snapshot.paramMap.get('hash');

    if(id) this.clientId = id
    if(pathHash) {
      this.hash = this._clientAreaService.decodeJwtToken(pathHash)
      this.isJWTValid = isJwtExpValid(this.hash.exp)
    }

    this.picture = this.hash.sub.logoUrl
  }

  ngOnInit(): void {
    this.getPathParams()

    if(this.clientId && this.isJWTValid){ 
      this.fetchCharges()
    }
  }
}
