// chat-widget.component.ts
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ChatService } from './chat.service';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
})
export class ChatWidgetComponent implements OnInit, OnDestroy {
  constructor( private chatService: ChatService ) {}

  ngOnInit() {
    this.chatService.loadChatScript();
  }

  ngOnDestroy() {
    this.chatService.removeChat();
  }
}