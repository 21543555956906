import { AuthService } from '../../features/auth/auth.service'
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class UserAlreadyLogged implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLogged()) {
      this.router.navigate([''])
      return false
    }

    return true
  }
}
