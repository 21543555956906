<div class="wrapper-auth">
  <div class="row">
    <div
      class="col-md-5 col-sm-12 px-0 d-flex justify-content-center align-items-center flex-column tile-login-form"
    >
      <img
        class="img-fluid mr-1"
        src="/assets/images/logo/logo-agilweb-black-orange.svg"
      />
      <p [ngClass]="{'agil-text': !isPhone,'hide-agil-text': isPhone
    }">Agilidade na gestão da sua central de rastreamento</p>
      <app-login-form [unsub]="unsubscribe$"></app-login-form>
    </div>

    <div class="" [ngClass]="{'col-md-7 px-0 side-login-container': !isPhone,'hide-login-container': isPhone
    }">

      <div class="about-us-container">
        <div> 
          <span>Sobre nós</span> 
        </div>
        <div class="social-media">
          <a href="https://www.instagram.com" target="_blank">
            <mat-icon inline=true svgIcon="instagram-icon" mat-list-icon></mat-icon>
          </a>
          <a href="https://www.facebook.com" target="_blank">
            <mat-icon inline=true svgIcon="facebook-icon" mat-list-icon></mat-icon>
          </a>
          <a href="https://www.whatsapp.com" target="_blank">
            <mat-icon inline=true svgIcon="whatsapp-icon" mat-list-icon></mat-icon>
          </a>
          </div>
      </div>

      <img class="login-side-image" src="/assets/images/login-side-image.svg" alt="working guy" />
    </div>
  </div>

  <app-chat-widget></app-chat-widget>

</div>

