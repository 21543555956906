import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt';

import { UserLoginModel, UserLoginResponse } from './user'
import { environment } from '../../../environments/environment'

interface UserProfile {
  id: number
  nome: string
  permissoes: string[]
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roleProfile: UserProfile

  constructor(
    private httpClient: HttpClient, 
    private router: Router,
    private jwtHelper: JwtHelperService) {}

  set token(token: string) {
    sessionStorage.setItem('access_token', token)
  }

  get token(): string {
    return sessionStorage.getItem('access_token') || ''
  }

  savePerfil(perfil: any) {
    sessionStorage.setItem('user_profile', JSON.stringify(perfil))
  }

  getPerfil(): any {
    let profile = sessionStorage.getItem('user_profile')
    if (profile) profile = JSON.parse(profile)
    return profile
  }

  

  isLogged(): boolean {
    return !!this.token
  }

  isAdmin(): boolean {

    const Decoded = this.decodeJwtToken(this.token)
    const adminStatus: boolean = Decoded.sub.is_admin

    return adminStatus || false
      
  }

  isMasterProfile() {
    const profile = this.getPerfil()
    return profile.id === 1
  }

  handleSaveProfile(token:string): void {

    const Decoded = this.decodeJwtToken(token)
    const profile: UserProfile = Decoded.sub.perfil
    this.savePerfil(profile)

  }

  decodeJwtToken(token:string): any {
    return this.jwtHelper.decodeToken(token)
  }

  isJwtExpValid(exp: number): boolean {

    let now = new Date()
    let expTime = new Date(exp * 1000);

    return now < expTime;

  }

  login(user: UserLoginModel) {
    return this.httpClient.post<UserLoginResponse>(environment.apiUrl + '/login', user)
  }

  logout() {
    sessionStorage.removeItem('access_token')
    this.router.navigateByUrl('/auth')
  }

  recoveryPassword(userForRecovery: any) {
    return this.httpClient.post<UserLoginResponse>(environment.apiUrl + '/login/recovery', userForRecovery)
  }
}
