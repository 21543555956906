import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core'
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {
  faBriefcase,
  faArrowRight,
  faCommentAlt,
  faColumns,
  faTools,
  faHardHat,
  faUsers,
  faStore,
  faSignOutAlt,
  faListUl
} from '@fortawesome/free-solid-svg-icons'

import { Router } from '@angular/router'
import { AuthService } from 'src/app/features/auth/auth.service'
import {MatDrawer} from "@angular/material/sidenav";
import {BaseComponentComponent} from "../../base-component/base-component.component";
import {AppResponsive} from "../../../app.module";
import { ACCESS_CONTROL_TYPES } from "../../../core/roles"



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends BaseComponentComponent implements OnInit {
  faBriefcase = faBriefcase
  faArrowRight = faArrowRight
  faCommentAlt = faCommentAlt
  faColumns = faColumns
  faTools = faTools
  faUsers = faUsers
  faStore = faStore
  faHardHat = faHardHat
  faSignOut = faSignOutAlt
  faServiceOrder = faListUl

  accessControl = ACCESS_CONTROL_TYPES

  constructor(
    private readonly router: Router,
    public authService: AuthService,
    
  ) {
    super()
  }

  @ViewChild('drawer')
  private matDrawer!: MatDrawer

  ngOnInit(): void {
    
    
  }
  

  handleRedirectTo(component: string) {
    this.router.navigateByUrl(component)
  }

  toggleSideBar() {

    this.matDrawer.toggle();


  }

  goToHomePage(): void {

    this.router.navigateByUrl('/dashboard')
    
  }

  handleLogout() {
    this.authService.logout()
  }
}
