<div class="bottom-sheet-body">

    <div class="close-icon-container" (click)="next()">
        <mat-icon class="close-icon" inline=true svgIcon="close-tab-icon" mat-list-icon >
        </mat-icon>
    </div>

    <mat-icon class="check-icon" inline=true svgIcon="green-check-circle" mat-list-icon ></mat-icon>
    <p class="text-center">
        {{ message ? message : 'Operação Realizada com Sucesso!'}}
    </p>

    <button mat-raised-button color="primary" (click)="next()">OK</button>

</div>