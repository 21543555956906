<header [ngClass]="{'header-phone': isPhone }">
    <img class="base-client-logo" src={{picture}} alt="logo-base-client">
    <h1 *ngIf="clientName"><span>Bem vindo(a),</span> {{clientName}}</h1>
</header>
<section class="agilweb-body">
    <div class="main-content">
            
        <div class="page-title">
            <h1>Cobranças</h1>
        </div>

        <mat-accordion [ngClass]="{'acordion-phone': isPhone }">
            <mat-expansion-panel [ngClass]="{'financial-container-phone ': isPhone, 'financial-container ' : !isPhone}"
                                    *ngFor="let charge of dataSource.data " >
                <mat-expansion-panel-header class="header-container ">
                    <mat-panel-title [ngClass]="{'main-description-container-phone': isPhone, 'main-description-container-web' : !isPhone }">

                        <div class="title-infomation name">
                            <span class="title">Nome</span>
                            <span class="description"> {{charge.name}} </span>
                        </div>

                        <div class="title-infomation">
                            <span class="title">status</span>
                            <span class="description" [ngClass]="{
                            'text-green': charge.status === 'RECEIVED' || charge.status === 'CONFIRMED' || charge.status === 'RECEIVED_IN_CASH' || charge.status === 'DUNNING_RECEIVED' || charge.status === 'CHARGEBACK_REQUESTED' || charge.status === 'DUNNING_RECEIVED',
                            'text-orange': charge.status === 'PENDING' || charge.status === 'REFUND_REQUESTED' || charge.status === 'CHARGEBACK_DISPUTE' || charge.status === 'AWAITING_CHARGEBACK_REVERSAL'  || charge.status === 'DUNNING_REQUESTED' || charge.status === 'AWAITING_RISK_ANALYSIS',
                            'text-red': charge.status  === 'OVERDUE' || charge.status  === 'REFUNDED'}"
                            >
                                {{translateStatus(charge.status)}}
                            </span>
                        </div>
                        <div class="title-infomation" *ngIf="!isPhone">
                            <span class="title">Vencimento</span>
                            <span class="description"> {{charge.dueDate | date : 'dd/MM/YYYY'   }}</span>
                        </div>

                        <div class="title-infomation value">
                            <span class="title">Valor</span>
                            <span class="description"> {{charge.value | currency}}</span>
                        </div>

                    </mat-panel-title>

                </mat-expansion-panel-header>

                <div class="expand-detail">

                    <div [ngClass]="{'flex-detail-phone': isPhone,
                    'flex-detail' : !isPhone }">

                        <div class="title-infomation mt-3">
                            <span class="title">Criada em</span>
                            <span class="description">{{charge.dateCreated | date : 'dd/MM/YYYY'  }}</span>
                        </div>

                        <div class="title-infomation mt-3" *ngIf="isPhone">
                            <span class="title">Vencimento</span>
                            <span class="description"> {{charge.dueDate | date : 'dd/MM/YYYY'   }}</span>
                        </div>

                        <div class="title-infomation mt-3" *ngIf="!isPhone">
                            <span class="title">Forma de pagamento</span>
                            <span class="description"> {{translateType(charge.billingType)}}</span>
                        </div>

                        <div class="title-infomation mt-3 ">
                            <span class="title">Numero da Fatura</span>
                            <a class="description" href="{{charge.invoiceUrl}}"
                            target="_blank"> {{charge.invoiceNumber}}</a>
                        </div>

                        <div class="title-infomation mt-3">
                            <span class="title">Desconto</span>
                            <span class="description">{{ charge.discount.value | currency}}</span>
                        </div>

                        <div class="title-infomation mt-3">
                            <span class="title">Juros e multa</span>
                            <span class="description">{{ (charge.fine.value * charge.value / 100) + (charge.interest.value * charge.value / 100 ) | currency}}</span>
                        </div>

                        <div class="title-infomation larger mt-3">
                            <span class="title">descrição</span>
                            <span class="description">{{charge.description}}</span>
                        </div>

                    </div>

                    <div class="control-area">
                        <button mat-icon-button matTooltip="Visualizar Boleto" matTooltipPosition="above" target="_blank" (click)="handleBoletoView(charge)">
                            <mat-icon>receipt_long</mat-icon>
                        </button>
                    </div>


                </div>

            </mat-expansion-panel>


        </mat-accordion>

        <div *ngIf="!isJWTValid" class="timed-out">
            <p>Ops, Sua sessão expirou! faça o acesso novamente.</p>
        </div>



        <mat-paginator #paginator

                [length]="dataSource?.totalCount"
                        [pageSize]=10
                        hidePageSize="true"
                        (page)="onPageChange($event)"
                        class="paginator"
        >
        </mat-paginator>

        
    </div>
</section>